import React from "react";
import Avatar from "react-avatar";
import { MediaQuery, useMediaQuery } from "../../hooks/useMediaQuery";
import "./style.scss";
import { ICON_ARROWBACK } from "../../Icons";
const ChatUser = ({ user, onBack }) => {
  const isSM = useMediaQuery(MediaQuery.sm);
  return (
    <div className="Chat-user">
      {!isSM && (
        <div
          onClick={onBack}
          style={{
            cursor: "pointer",
          }}
        >
          <ICON_ARROWBACK />
        </div>
      )}
      <Avatar name={user.name} src={user.profileImg} size={50} round={true} />
      <div>
        <p
          style={{
            fontSize: "1.1rem",
            fontWeight: "700",
            color: "#1E1F23",
          }}
        >
          {user.name}
        </p>
      </div>
    </div>
  );
};

export default ChatUser;
