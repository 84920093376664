import React, { useRef, useState } from "react";
import TextAreaAutoSize from "react-textarea-autosize";
import "./style.scss";
import { useCreateChatMessage } from "../../client-api";
import axiosLib from "../../axiosLib";
import { API_URL } from "../../config";
import { supportedImageFiletypes } from "../../utils/common";
const ChatInput = ({ account, roomId, scrollToBottom }) => {
  const inputRef = useRef(null);
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const { mutateAsync: createChatMessage, isLoading: composing } =
    useCreateChatMessage(roomId);

  const onTakePhoto = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onChangeImage = (e) => {
    const files = e.target.files;
    let bodyFormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      bodyFormData.append("images", files[i]);
    }

    axiosLib
      .post(`${API_URL}/carvibes/upload`, bodyFormData)
      .then((res) => {
        const imgUrl =
          res.data.imageUrls.length > 0 ? res.data.imageUrls[0] : null;
        setSelectedFile(imgUrl);
      })
      .catch((err) => {
        console.log(err);
        alert("error uploading image");
      });
  };

  const sendMessage = async () => {
    if (!message && !selectedFile) {
      return;
    }
    await createChatMessage({
      roomId,
      messageText: message ?? "",
      imageUrl: selectedFile ?? "",
    });
    setMessage("");
    setSelectedFile(null);
    scrollToBottom();
  };

  const onKeyDown = (e) => {
    const keyCode = e.keyCode ?? e.which;
    if (keyCode === 13 && e.ctrlKey) {
      if (composing) {
        return;
      }
      sendMessage();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className="Chat-input-container">
      <div className="Chat-input-inner-container">
        <div className="Chat-input-button-container">
          <img onClick={onTakePhoto} src={"/ImageSquare.svg"} alt="profile" />
          <input
            ref={inputRef}
            style={{ display: "none" }}
            type="file"
            multiple={false}
            accept={supportedImageFiletypes.join(", ")}
            onChange={onChangeImage}
          />
        </div>
        <TextAreaAutoSize
          type="text"
          style={{
            width: "100%",
            padding: "6px",
            backgroundColor: "#e5e7e7",
            fontSize: "1rem",
            fontWeight: "500",
          }}
          placeholder="メッセージ"
          value={message}
          cacheMeasurements={true}
          onChange={(e) => {
            setMessage(e.currentTarget.value);
          }}
          onKeyDown={onKeyDown}
        />
        <div className="Chat-input-button-container">
          <img onClick={sendMessage} src={"/PaperPlaneRight.svg"} alt="send" />
        </div>
      </div>
      {selectedFile && (
        <div className="Chat-input-image-container">
          <img
            src={selectedFile}
            alt="chat-image"
            className="Chat-input-image"
          />
        </div>
      )}
    </div>
  );
};
export default ChatInput;
