export const country_lng = {
  US: "EN-US",
  GB: "EN-GB",
  JP: "JA",
  FR: "FR",
  CN: "ZH",
  DE: "DE",
  PT: "pt-PT",
  ES: "ES",
  IT: "IT",
};

export const country_list = [
  "JP",
  "US",
  "GB",
  "ES",
  "FR",
  "DE",
  "IT",
  "PT",
  "CN",
];

export const country_labels = {
  JP: "日本語",
  US: "English(US)",
  GB: "English(UK)",
  FR: "Francés",
  DE: "Deutsch",
  PT: "Portugués",
  ES: "Español",
  IT: "Italiano",
  CN: "中文",
};
