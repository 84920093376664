import moment from "moment";
import React, { useState } from "react";
import Avatar from "react-avatar";
const ChatOutgoingMessage = ({ message }) => {
  const [popUpImageSrc, setPopUpImageSrc] = useState("");
  const messageContent = message.message;

  const imageClassName = !!messageContent.messageText
    ? "image-outgoing-combine"
    : "image-outgoing-only";

  const messageClassName = !!messageContent.imageUrl
    ? "outgoing-message-combine"
    : "outgoing-message-only";
  return (
    <div className="Chat-outgoing-message-container">
      <div className="Chat-outgoing-message-item-container">
        {messageContent.imageUrl && (
          <img
            src={messageContent.imageUrl}
            alt="chat-image"
            className={`Chat-message-image ${imageClassName}`}
            onClick={() =>setPopUpImageSrc(messageContent.imageUrl)}
          />
        )}
        {messageContent.messageText && (
          <div
            className={`Chat-outgoing-message-text-container ${messageClassName}`}
          >
            <p>{messageContent.messageText}</p>
          </div>
        )}
        <p
          style={{
            marginLeft: "auto",
            fontSize: 12,
            color: "#333",
            marginTop: 6,
          }}
        >
          {moment(message.updatedAt).format("hh:mm A")}
        </p>
      </div>

      {popUpImageSrc !== "" ? (
        <div
          onClick={() => setPopUpImageSrc("")}
          className="chat-image-modal-container "
        >
          <div
            id="imageCarouselModal"
            className="chat-image-modal "
            onClick={(e) => e.stopPropagation()}
          >
            <img
              alt="chat "
              src={popUpImageSrc}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default ChatOutgoingMessage;
