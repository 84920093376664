import React from 'react'
import { useContext, useState, useEffect } from 'react'
import { StoreContext } from '../../../store/store';
import './style.scss';
import { Link, Redirect } from 'react-router-dom';
import Loading from "../../loading";

const LOGIN_SCREEN_TEXT = [
    'ログイン',
    `ユーザーID`,
    'パスワード',
    'サインアップ',
];

const AdminLogin = () => {
    const { state, actions } = useContext(StoreContext);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const currentToken = localStorage.getItem('carvibestoken');
        if (currentToken && currentToken.length > 0) {
            window.location = '/admin';
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (email.length === 0 || password.length === 0) {
            actions.setError(
                {
                    msg: 'メールアドレスまたはパスワードが空です。',
                }
            );
            return;
        }
        const values = {
            email,
            password,
        };
        actions.adminLogin(values);
    }

    if (state.loading) return Loading();
    return (
        <div className={'container'}>
            {state.loggedin && <Redirect to="/admin" />}
            <div className={'container2'}
                style={{
                    height: '100vh',
                }}
            >
                <div className={'container3'} style={{
                    height: '100%',
                    alignItems: 'center',
                }}>
                    <div
                        style={{
                            background: 'white',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                    />
                    <div className={'feature-card1'}
                        style={{
                            boxShadow: '0px 2px 8px 2px rgba(29, 117, 101, 0.1)',
                            width: 480,
                            height: 526,
                            padding: '60px 40px',
                        }}
                    >
                        <h2 className={'text03'}
                            style={{
                                marginTop: 0,
                                marginBottom: 80,
                                fontSize: 20,
                            }}
                        >{LOGIN_SCREEN_TEXT[0]}
                        </h2>
                        <form onSubmit={handleSubmit}
                            style={{
                                width: '100%',
                            }}
                        >
                            <div>
                                <div className={'text-email'}>{LOGIN_SCREEN_TEXT[1]}</div>
                                <input
                                    type="email"
                                    className={'email-input-wrap'}
                                    placeholder={LOGIN_SCREEN_TEXT[1]}
                                    value={email}
                                    onChange={(e) => {
                                        actions.resetError();
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                            <div style={{
                                marginBottom: 80,
                            }}>
                                <div className={'text-email'}>{LOGIN_SCREEN_TEXT[2]}</div>
                                <input
                                    type="password"
                                    className={'email-input-wrap'}
                                    placeholder={LOGIN_SCREEN_TEXT[2]}
                                    value={password}
                                    onChange={(e) => {
                                        actions.resetError();
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                            <button
                                onClick={handleSubmit}
                                type="submit"
                                form="loginForm"
                                className={'admin-login-btn-wrap button-active'}
                                style={{ cursor: 'pointer' }}
                            >
                                {LOGIN_SCREEN_TEXT[3]}
                            </button>
                        </form>
                        {state.error && <p style={{
                            color: 'red',
                        }}>{state.msg}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;