import React from "react";
import Avatar from "react-avatar";

const UserAvatar = ({ user, size = 49, round = true }) => {
  return (
    <Avatar
      name={user.name}
      src={user.profileImg}
      size={size}
      round={round}
    ></Avatar>
  );
};
export default UserAvatar;
