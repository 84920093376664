import types from "./typeActions";
import jwt_decode from "jwt-decode";

export const useActions = (state, dispatch) => ({
  fbLogin: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.FACEBOOK_LOGIN, payload: data });
  },
  googleLogin: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.GOOGLE_LOGIN, payload: data });
  },
  adminLogin: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.ADMIN_LOGIN, payload: data });
  },
  signup: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.REGISTER, payload: data });
  },
  setError: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: false } });
    dispatch({ type: types.ERROR, payload: data });
  },
  resetError: () => {
    dispatch({ type: types.RESET_ERROR });
  },
  carvibes: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.CARVIBES, payload: data });
  },
  likeCarvibes: (data) => {
    dispatch({ type: types.LIKE_CARVIBES, payload: data });
  },
  getCarvibess: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.GET_CARVIBESS, payload: data });
  },
  bookmarkCarvibes: (data) => {
    dispatch({ type: types.BOOKMARK, payload: data });
  },
  getCarvibes: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.GET_CARVIBES, payload: data });
  },
  verifyToken: (data) => {
    if (localStorage.getItem("carvibestoken")) {
      const jwt = jwt_decode(localStorage.getItem("carvibestoken"));
      const current_time = new Date().getTime() / 1000;
      if (current_time > jwt.exp) {
        dispatch({ type: types.SET_STATE, payload: { session: false } });
        localStorage.removeItem("carvibestoken");
      } else {
        if (data === "get account") {
          dispatch({ type: types.GET_ACCOUNT });
        }
        dispatch({
          type: types.SET_STATE,
          payload: { session: true, decoded: jwt },
        });
      }
    } else {
      dispatch({ type: types.SET_STATE, payload: { session: false } });
    }
  },
  getUser: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.GET_USER, payload: data });
  },
  getBookmarks: (data) => {
    dispatch({ type: types.GET_BOOKMARKS });
  },
  updateUser: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.UPDATE_USER, payload: data });
  },
  recarvibes: (data) => {
    dispatch({ type: types.RECARVIBES, payload: data });
  },
  deleteCarvibes: (data) => {
    dispatch({ type: types.DELETE_CARVIBES, payload: data });
  },
  followUser: (data) => {
    dispatch({ type: types.FOLLOW_USER, payload: data });
  },
  followerUser: (data) => {
    dispatch({ type: types.FOLLOWER_USER, payload: data });
  },
  editList: (data) => {
    dispatch({ type: types.EDIT_LIST, payload: data });
  },
  createList: (data) => {
    dispatch({ type: types.CREATE_LIST, payload: data });
  },
  deleteList: (data) => {
    dispatch({ type: types.DELETE_LIST, payload: data });
  },
  getLists: (data) => {
    dispatch({ type: types.GET_LISTS, payload: data });
  },
  logout: () => {
    localStorage.removeItem("carvibestoken");
    window.location.reload();
  },
  getList: (data) => {
    dispatch({ type: types.GET_LIST, payload: data });
  },
  getTrend: (data) => {
    dispatch({ type: types.GET_TREND, payload: data });
  },
  search: (data) => {
    dispatch({ type: types.SEARCH, payload: data });
  },
  getTrendCarvibess: (data) => {
    dispatch({ type: types.TREND_CARVIBESS, payload: data });
  },
  addToList: (data) => {
    dispatch({ type: types.ADD_TO_LIST, payload: data });
  },
  getFollowers: (data) => {
    dispatch({ type: types.GET_FOLLOWERS, payload: data });
  },
  getFollowing: (data) => {
    dispatch({ type: types.GET_FOLLOWING, payload: data });
  },
  searchUsers: (data) => {
    dispatch({ type: types.SEARCH_USERS, payload: data });
  },
  whoToFollow: (data) => {
    dispatch({ type: types.WHO_TO_FOLLOW, payload: data });
  },
  alert: (data) => {
    dispatch({ type: types.SET_STATE, payload: { top: "16px", msg: data } });
    setTimeout(() => {
      dispatch({ type: types.SET_STATE, payload: { top: "-100px" } });
    }, 2700);
  },
  getConversations: (data) => {
    dispatch({ type: types.GET_CONVERSATIONS, payload: data });
  },
  startChat: (data) => {
    dispatch({ type: types.SET_STATE, payload: { startingChat: true } });
    dispatch({ type: types.START_CHAT, payload: data });
  },
  getSingleConversation: (data) => {
    dispatch({ type: types.GET_SINGLE_CONVERSATION, payload: data });
  },
  reportCarvibes: (data) => {
    dispatch({ type: types.REPORT, payload: data });
  },
  getReportedUsers: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.GET_REPORTS, payload: data });
  },
  deleteReport: (data) => {
    dispatch({ type: types.DELETE_REPORT, payload: data });
  },
  processReport: (data) => {
    dispatch({ type: types.PROCESS_REPORT, payload: data });
  },
  updateReportedUsers: (data) => {
    dispatch({ type: types.UPDATE_REPORT, payload: data });
  },
  getUsers: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.GET_USERS, payload: data });
  },
  warnUser: (data) => {
    dispatch({ type: types.WARN_USER, payload: data });
  },
  deleteUser: (data) => {
    dispatch({ type: types.DELETE_USER, payload: data });
  },
  getLogs: (data) => {
    dispatch({ type: types.GET_LOGS, payload: data });
  },
  sendNotification: (data) => {
    dispatch({ type: types.SET_STATE, payload: { loading: true } });
    dispatch({ type: types.SEND_NOTIFICATION, payload: data }); },
});
