import React, { useState, useEffect } from "react";
import "./style.scss";

const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);
  const [direction, setDirection] = useState(props.direction || "top");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 375 || (width >= 768 && width <= 1200)) {
        setDirection("right");
      } else {
        setDirection(props.direction || "top");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.direction]);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onClick={() => setActive(!active)} 
    >
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${direction}`}>
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;