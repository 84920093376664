import { useState } from "react";
import axios from "../axiosLib";
import { useEffect } from "react";
import { API_URL } from "../config";
import { country_lng } from "../constants/language";

const getInitLanguageCode = () => {
  if (localStorage.getItem("lang")) {
    return localStorage.getItem("lang");
  }
  return null;
};

export const useLocalization = (initTexts) => {
  const [countryCode, setCountryCode] = useState(getInitLanguageCode() ?? "JP");
  const [translatedTexts, setTranslatedTexts] = useState(initTexts);
  const onUpdateTexts = (texts) => {
    if (countryCode.toLowerCase() === "jp") {
      setTranslatedTexts(initTexts);
      return;
    }
    axios
      .post(`${API_URL}/translate`, {
        texts,
        destination: country_lng[countryCode],
      })
      .then((res) => {
        let resp = res.data;
        const newTexts = resp.map((r) => r.text.replace(/ *\([^)]*\) */g, ""));
        setTranslatedTexts(newTexts);
      })
      .catch((err) => console.error("error translate"));
  };

  useEffect(() => {
    if (initTexts.length > 0) {
      onUpdateTexts(initTexts);
    }
  }, [initTexts.length, countryCode]);

  return {
    countryCode,
    translatedTexts,
    onUpdateTexts,
    onSelectCountryCode: setCountryCode,
  };
};
