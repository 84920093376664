import React, { Suspense, lazy, useState } from "react";
import { Route, BrowserRouter, Redirect, withRouter } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import { StoreProvider } from "./store/store";
import "dotenv/config";
import "./App.scss";
import Loader from "./components/Loader";
import Nav from "./components/Nav";
import AdminNav from "./components/Admin/AdminNav";
import Login from "./components/Login";
import Carvibes from "./components/Carvibes";
import Explore from "./components/Explore";
import Settings from "./components/Settings";
import Feed from "./components/Feed";
import Chat from "./components/Chat";

import ProfileEdit from "./components/ProfileEdit";
import CarEdit from "./components/CarEdit";
import AdminLogin from "./components/Admin/Login";
import UserList from "./components/Admin/UserList";
import UserDetail from "./components/Admin/UserDetail";
import NewUserHistory from "./components/Admin/NewUserHistory";
import SendNotification from "./components/Admin/SendNotification";
import ManagementHistory from "./components/Admin/ManageHistory";
import { useSplashAnimation } from "./hooks/useSplashAnimation";
import SurveyBadge from "./components/SurveyBadge";
import CompanyPage from './components/Company';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';

const Home = lazy(() => import("./components/Home"));
const AdminHome = lazy(() => import("./components/Admin/Home"));
const Profile = lazy(() => import("./components/Profile"));

const HIDE_NAV_PAGES = [
  /^\/settings\/[0-9a-fA-F]+\/profile-edit$/,
  /^\/settings\/[0-9a-fA-F]+\/car-edit$/,
  /^\/login\/[0-9a-fA-F]+\/car-edit$/,
  /^\/admin-login/,
  /^\/admin\/signup/,
];

const DefaultAdminContainer = withRouter(({ history }) => {
  const hideNav = HIDE_NAV_PAGES.some((regExp) =>
    history.location.pathname.match(regExp)
  );
  return (
    <div className="body-wrap">
      <nav
        className="header"
        style={{
          display: hideNav ? "none" : "block",
        }}
      >
        <AdminNav />
      </nav>
      <main className="main">
        <div
          className={"middle-section ms-width"}
          style={{
            backgroundColor: "white",
          }}
        >
          <Route path="/admin" exact>
            <AdminHome />
          </Route>
          <Route path="/admin-login" exact>
            <AdminLogin />
          </Route>
          <Route path="/admin/user-list" exact>
            <UserList />
          </Route>
          <Route path="/admin/user-list/:userid" exact>
            <UserDetail />
          </Route>
          <Route path="/admin/new-user-history" exact>
            <NewUserHistory />
          </Route>
          <Route path="/admin/send-notification" exact>
            <SendNotification />
          </Route>
          <Route path="/admin/manage-history" exact>
            <ManagementHistory />
          </Route>
        </div>
      </main>
    </div>
  );
});

const DefaultContainer = withRouter(({ history }) => {
  const hideNav = HIDE_NAV_PAGES.some((regExp) =>
    history.location.pathname.match(regExp)
  );

  if (history.location.pathname.includes("/login")) {
    return (
      <Route path="/login" exact>
        <Login />
      </Route>
    );
  }

  if (history.location.pathname.includes("/admin")) {
    return <Route component={DefaultAdminContainer} />;
  }

  if (history.location.pathname.includes("/company")) {
    return (
      <Route path="/company" exact>
        <CompanyPage />
      </Route>
    );
  }

  if (history.location.pathname.includes("/privacy-policy")) {
    return (
      <Route path="/privacy-policy" exact>
        <PrivacyPolicy />
      </Route>
    );
  }

  if (history.location.pathname.includes("/terms-of-use")) {
    return (
      <Route path="/terms-of-use" exact>
        <TermsOfUse />
      </Route>
    );
  }
  
  return (
    <div className="body-wrap">
      <nav
        className="header"
        style={{
          display: hideNav ? "none" : "block",
        }}
      >
        <Nav />
      </nav>
      <main className="main">
        <div
          className={
            history.location.pathname.slice(0, 9) !== "/messages"
              ? "middle-section ms-width"
              : "middle-section"
          }
        >
          <SurveyBadge />
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact>
            <Home />
          </Route>

          <Route path="/feed" exact>
            <Feed />
          </Route>

          <Route path="/chat" exact>
            <Chat />
          </Route>
          <Route path="/profile/:userid" exact>
            <Profile />
          </Route>
          <Route path="/carvibes/:userid/:id" exact>
            <Carvibes />
          </Route>
          <Route path="/explore" exact>
            <Explore />
          </Route>
          <Route path="/settings/:userid" exact>
            <Settings />
          </Route>
          <Route path="/settings/:userid/profile-edit" exact>
            <ProfileEdit />
          </Route>
          <Route path="/settings/:userid/car-edit" exact>
            <CarEdit />
          </Route>
        </div>
      </main>
    </div>
  );
});

function App() {
  useSplashAnimation();
  const [queryClient] = useState(() => new QueryClient());

  return (
    <div className="dark-mode">
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <Route path="/" component={DefaultContainer} />
              <ToastContainer />
            </Suspense>
          </BrowserRouter>
        </StoreProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
