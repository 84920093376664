import { useEffect } from "react";
import io from "socket.io-client";
import { API_URL } from "../config";

let socket;
const hostUrl = API_URL;

export const useSocketClient = ({ subscribeId, handleMsg }) => {
  const socketInitializer = async () => {
    // We just call it because we don't need anything else out of it
    socket = io(hostUrl, {
      transports: ["websocket"],
    });
    socket.on(subscribeId, (msg) => {
      handleMsg(msg);
    });
    socket.on("connect", () => {
      console.log("on connect", socket.id); // x8WIv7-mJelg7on_ALbx
    });
    socket.on("disconnect", () => {
      console.log("on disconnect", socket.id); // undefined
    });
  };

  const socketDisconnect = () => {
    if (!socket) return;
    socket.off("connect", () => {
      console.log("off connect", socket.id); // x8WIv7-mJelg7on_ALbx
    });
    socket.off("disconnect", () => {
      console.log("off disconnect", socket.id); // undefined
    });
    socket.off(subscribeId, () => {
      console.log("off disconnect", subscribeId);
    });
  };

  const emitMessage = (channelId, data) => {
    socket.emit(channelId, data);
  };

  useEffect(() => {
    socketInitializer();
    return () => {
      socketDisconnect();
    };
  }, [subscribeId]);

  return {
    emitMessage,
  };
};
