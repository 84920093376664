import React, { useEffect, useContext, useState, useRef } from "react";
import { StoreContext } from "../../../store/store";
import "./style.scss";

const SendNotification = () => {
  const { state, actions } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const subjectRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentToken = localStorage.getItem("carvibestoken");
    if (currentToken && currentToken.length > 0) {

    } else {
      window.location = "/admin-login";
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSendNotification = () => {
    const subjectValue = subjectRef.current.value;
    const contentValue = contentRef.current.value;

    if (subjectValue && contentValue) {
      setShowConfirmationModal(true);
      return;
    }

    setShowWarningModal(true);
    return
  }

  const sendNotification = () => {
    const data = {
      "subject": subjectRef.current.value,
      "content": contentRef.current.value,
    }
    actions.sendNotification(data);
  }

  return (
    <div className="admin-wrapper" style={{
      backgroundColor: "#fff"
    }}>
      <div className="admin-header-wrapper">
        <h2 className="admin-title">通知送信</h2>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="admin-content-wrapper" style={{ maxWidth: "600px" }}>
          <div>
            <p className="sub-header">件名</p>
            <textarea
              id="subject-text"
              className="subject-input"
              placeholder="通知内容を記入。"
              ref={subjectRef}
            />
          </div>

          <div className="admin-form-group">
            <p className="sub-header">本文</p>
            <textarea
              id="notification-text"
              className="content-input"
              placeholder="通知内容を記入。"
              ref={contentRef}
            />
          </div>
          <div
            style={{ justifyContent: "flex-end", display: "flex" }}
            onClick={onSendNotification}
          >
            <div
              className="Nav-item-hover-admin active-Nav-admin"
              style={{
                marginTop: "20px",
                width: "160px",
                height: "40px",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <div className="Nav-item-admin">
                送信する
              </div>
            </div>
          </div>
        </div>
      </form>

      {showConfirmationModal ? (
        <div
          className="modal-edit"
          onClick={() => {
            setShowConfirmationModal(false);
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "350px",
              height: "175px",
              textAlign: "center",
              display: showConfirmationModal ? "block" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p style={{
                fontWeight: "bold",
                fontSize: "19px",
              }}>
                メールを送信しますか?
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={sendNotification}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Yes
                </div>

                <div
                  onClick={() => {
                    setShowConfirmationModal(false);
                  }}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  No
                </div>
              </div>
            </div>
          </div>

        </div>) : null}

      {showWarningModal ? (
        <div
          className="modal-edit"
          onClick={() => {
            setShowConfirmationModal(false);
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "350px",
              height: "175px",
              textAlign: "center",
              display: showWarningModal ? "block" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p style={{
                fontWeight: "bold",
                fontSize: "19px",
              }}>
                Empty Fields
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={() => {
                    setShowWarningModal(false);
                  }}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>

        </div>) : null}

      {state.msg === 'complete' ? (
        <div
          className="modal-edit"
          onClick={() => {
            actions.alert("");
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "350px",
              height: "175px",
              textAlign: "center",
              display: state.msg === 'complete' ? "block" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p style={{
                fontWeight: "bold",
                fontSize: "19px",
              }}>
                Sent Notification
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={() => {
                    actions.alert("");
                    subjectRef.current.value = "";
                    contentRef.current.value = "";
                  }}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>) : null}
    </div >
  );
};

export default SendNotification;
