import { useMutation, useQueryClient } from "react-query";
import { getChatRoomsKey, getChatConversationKey } from "./queryKey";
import axiosLib from "../../axiosLib";
import { API_URL } from "../../config";

export const createChatRoomRequest = async (userIds) => {
  const response = await axiosLib.post(`${API_URL}/chat/initiate`, {
    userIds,
  });
  if (response.data.success) {
    return response.data.chatRoom;
  }
  return null;
};

export const createChatMessageRequest = async ({
  roomId,
  messageText,
  imageUrl,
}) => {
  const response = await axiosLib.post(`${API_URL}/chat/${roomId}/message`, {
    messageText,
    imageUrl,
  });
  return response.data;
};

export const updateChatMessageRequest = async (roomId) => {
  const response = await axiosLib.put(`${API_URL}/chat/${roomId}/mark-read`);
  return response.data;
};

export const useCreateChatRoom = () => {
  const queryClient = useQueryClient();
  return useMutation(createChatRoomRequest, {
    onSettled: () => {
      queryClient.invalidateQueries(getChatRoomsKey());
    },
  });
};

export const useCreateChatMessage = (roomId) => {
  const queryClient = useQueryClient();
  return useMutation(createChatMessageRequest, {
    onSettled: () => {
      queryClient.invalidateQueries(getChatConversationKey(roomId));
    },
  });
};

export const useUpdateChatMessage = (roomId) => {
  const queryClient = useQueryClient();
  return useMutation(updateChatMessageRequest, {
    onSettled: () => {
      queryClient.invalidateQueries(getChatConversationKey(roomId));
      queryClient.invalidateQueries(getChatRoomsKey());
    },
  });
};
