import axios from "axios";
import { API_URL } from "../config";
import { country_lng } from "../constants/language";

const getLanguageCode = () => {
  const language = localStorage.getItem("lang") ?? "JP";
  return country_lng[language];
};

const onRequest = (config) => {
  if (config.headers) {
    config.headers["page-url"] = window.location.href;
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "carvibestoken"
    )}`;
    config.headers["Lang"] = getLanguageCode();
  }
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = (error) => {
  return Promise.reject(error);
};

function setupInterceptorsTo(axiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = API_URL;
axiosInstance.defaults.withCredentials = false;

export default setupInterceptorsTo(axiosInstance);
