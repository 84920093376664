import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../store/store";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { ICON_SEARCH, ICON_ARROWBACK, ICON_TAG } from "../../Icons";
import Loader from "../Loader";
import CarvibesCard from "../CarvibesCard";
import Axios from "../../axiosLib";
import { API_URL } from "../../config";
import { country_lng } from "../../constants/language";
import {
  useGetAllCategories,
  useSearchCarvibes,
} from "../../hooks/useSearchCarvibes";
import { useLocalization } from "../../hooks/useLocalization";
const initTexts = [
  "キーワードを入力してください",
  "カテゴリから探す",
  "タグから探す",
];
const Explore = (props) => {
  const { state, actions } = useContext(StoreContext);
  const { result, tagCarvibess } = state;
  const [tab, setTab] = useState("Trends");
  const [trendOpen, setTrendOpen] = useState(false);
  const [params, setParams] = useState();
  const [texts, setTexts] = useState(initTexts);
  const { categories, hashTags } = useSearchCarvibes();
  const { translatedTexts, onUpdateTexts } = useLocalization(texts);

  useEffect(() => {
    if (categories.length > 0) {
      setTexts([...initTexts, ...categories.map((item) => item.content)]);
    }
  }, [categories]);

  useEffect(() => {
    onUpdateTexts(texts);
  }, [texts.length]);

  const searchOnChange = (param) => {
    if (tab !== "Search") {
      setTab("Search");
    }
    if (param.length > 0) {
      actions.search({ description: param });
    } else {
      setTab("Trends");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    actions.getTrend();
    const currentToken = localStorage.getItem("carvibestoken");
    if (!(currentToken && currentToken.length > 0)) {
      window.location = "/login";
    }
  }, []);

  const goToTrend = (type, id) => {
    setParams({ type, id });
    setTrendOpen(true);
    const query = `${type}?id=${id}`;
    actions.getTrendCarvibess(query);
  };

  return (
    <div className="explore-wrapper">
      <div
        className={
          trendOpen ? "explore-header header-border" : "explore-header"
        }
      >
        {trendOpen && (
          <div className="explore-header-back">
            <div
              onClick={() => setTrendOpen(false)}
              className="explore-back-wrapper"
            >
              <ICON_ARROWBACK />
            </div>
          </div>
        )}
        <div className="explore-search-wrapper">
          <div className="explore-search-input">
            <input
              autoFocus
              onChange={(e) => searchOnChange(e.target.value)}
              placeholder={translatedTexts[0]}
              type="text"
              name="search"
            />
          </div>
          <div className="explore-search-icon">
            <ICON_SEARCH />
          </div>
        </div>
      </div>

      <div>
        {!trendOpen &&
          (tab === "Trends" ? (
            <div>
              {categories.length > 0 ? (
                <div>
                  <p className="explore-section-title">{translatedTexts[1]}</p>
                  <div className="explore-grid">
                    {categories.map((t, i) => {
                      const textIndex = i + initTexts.length - 1;
                      return (
                        <div
                          onClick={() => goToTrend("category", t.id)}
                          key={t._id}
                          className="trending-card-wrapper category"
                        >
                          <div className="trending-category-text">
                            {t.content}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
              {hashTags.length > 0 ? (
                <div>
                  <p className="explore-section-title">{translatedTexts[2]}</p>
                  <div className="explore-grid">
                    {hashTags.map((t, i) => {
                      return (
                        <div
                          onClick={() => goToTrend("hashtag", t.id)}
                          key={t._id}
                          className="trending-card-wrapper hashtag"
                        >
                          <div className="">
                            <ICON_TAG />
                          </div>
                          <div className="trending-hashtag-text">
                            {" "}
                            {t.content}{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          ) : result.length ? (
            <>
              <div className="Home-carvibes-root">
                {result.map((r) => {
                  return (
                    <CarvibesCard
                      showFollow={true}
                      clickableImage={true}
                      carvibesUpdated={(id) => {
                        setTimeout(() => {
                          if (params) {
                            goToTrend(params.type, params.id);
                          }
                        }, 500);
                      }}
                      recarvibes={r.recarvibes}
                      username={r.username}
                      name={r.name}
                      parent={r.parent}
                      key={r._id}
                      id={r._id}
                      user={r.user}
                      createdAt={r.createdAt}
                      description={r.description}
                      images={r.images}
                      replies={r.replies}
                      likes={r.likes}
                    />
                  );
                })}
              </div>
              <img
                src="/scroll.svg"
                alt="scrollToTop"
                className="scroll-to-top"
                onClick={() => window.scrollTo(0, 0)}
              />
            </>
          ) : (
            <div className="try-searching">
              Nothing to see here ..
              <div />
              Try searching for people, usernames, or keywords
            </div>
          ))}
        {tagCarvibess && tagCarvibess.length > 0 && trendOpen && (
          <>
            <div className="Home-carvibes-root">
              {tagCarvibess.map((r) => {
                return (
                  <CarvibesCard
                    showFollow={true}
                    clickableImage={true}
                    carvibesUpdated={(id) => {
                      setTimeout(() => {
                        if (params) {
                          goToTrend(params.type, params.id);
                        }
                      }, 500);
                    }}
                    recarvibes={r.recarvibes}
                    username={r.username}
                    name={r.name}
                    parent={r.parent}
                    key={r._id}
                    id={r._id}
                    user={r.user}
                    createdAt={r.createdAt}
                    description={r.description}
                    images={r.images}
                    replies={r.replies}
                    likes={r.likes}
                  />
                );
              })}
            </div>
            <img
              src="/scroll.svg"
              alt="scrollToTop"
              className="scroll-to-top"
              onClick={() => window.scrollTo(0, 0)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Explore);
