import { useState } from "react";
import axios from "../axiosLib";
import { useEffect } from "react";
import { API_URL } from "../config";

export const useSearchCarvibes = () => {
  const [categories, setCategories] = useState([]);
  const [hashTags, setHashTags] = useState([]);

  const [loading, setLoading] = useState(false);
  const onGetAllTrends = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/trend/all`)
      .then((res) => {
        const respHashTags = res.data.hashtags;
        const respCategories = res.data.categories;
        setLoading(false);
        if (
          respHashTags &&
          Array.isArray(respHashTags) &&
          respHashTags.length > 0
        ) {
          const hashTagsList = respHashTags.map((item) => {
            return {
              id: item._id,
              content: item.content,
            };
          });
          setHashTags(hashTagsList);
        }
        if (
          respCategories &&
          Array.isArray(respCategories) &&
          respCategories.length > 0
        ) {
          const categoryList = respCategories.map((item) => {
            return {
              id: item._id,
              content: item.content,
            };
          });
          setCategories(categoryList);
        }
      })
      .catch((err) => {
        console.error("error get category", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    onGetAllTrends();
  }, []);

  return {
    categories,
    hashTags,
    loading,
  };
};
