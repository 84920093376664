import React, { useEffect } from "react";

export const useSplashAnimation = () => {
  useEffect(() => {
    const spinner = document.querySelector("#spinner");
    const loading = spinner.querySelector(".loading");
    loading.style.backgroundImage = "url(/loading.gif)";
    localStorage.setItem("repeater", "1");
    setTimeout(() => {
      spinner.style.display = "none";
    }, 4000);
  }, []);
};
