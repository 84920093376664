import React from "react";

function Loading() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
        position: "fixed",
        zIndex: 990,
        left: 0,
        top: 0,
      }}
    >
      <div className="spnr" />
      <img
        alt="loading"
        src="/loading.png"
        style={{
          width: "200px",
          height: "200px",
          marginLeft: "-210px",
          position: "relative",
          zIndex: 1001,
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 999,
        }}
      ></div>
    </div>
  );
}
export default Loading;
