import React, { useEffect, useContext } from "react";
import "../Profile/style.scss";
import "./style.scss";
import { ICON_SEARCH, ICON_ARROWFRONT, ICON_LOGOUT } from "../../Icons";
import { withRouter, Link } from "react-router-dom";
import { StoreContext } from "../../store/store";
import Loader from "../Loader";
import ReactFlagsSelect from "react-flags-select";
import { country_labels, country_list } from "../../constants/language";
import { useLocalization } from "../../hooks/useLocalization";
import { useGetSurvey } from "../../client-api/survey";
import { getFlagCodeFromLangCode, getLangCodeFromLangString } from '../../utils/common';

const SCREEN_TEXT = [
  "キーワードを入力してください",
  "設定",
  "愛車情報",
  "愛車名",
  "アカウント情報",
  "Google連携",
  "お問合せ",
  "T&Fアセットマネジメント株式会社 (T&F ASSET MANAGEMENT INC.)",
  "代表者・創設者：大越 隆行（Takayuki Ogoshi)",
  "ログアウト",
];

const Settings = (props) => {
  const { state, actions } = useContext(StoreContext);
  const { user } = state;
  const userParam = props.match.params.userid;
  const { data: surveyData, isLoading: iSurveyLoading } = useGetSurvey(
    user?._id
  );
  const {
    translatedTexts: texts,
    onSelectCountryCode,
    countryCode,
  } = useLocalization(SCREEN_TEXT);

  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (!(currentToken && currentToken.length > 0)) {
      window.location = "/login";
    }
    window.scrollTo(0, 0);
    actions.getUser(props.match.params.userid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.userid]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  const getFlagCode = () => {
    const nativeLang = getFlagCodeFromLangCode(user.nativeLang)

    if (nativeLang === 'JP' && countryCode !== 'JP') {
      return countryCode;
    }

    return nativeLang;
  }

  return (
    <div className="profile-root">
      <div
        className="btn-hide"
        style={{
          marginTop: "10px",
          padding: "10px",
          backgroundColor: "#f5f8fa",
        }}
      >
        <div className="explore-search-wrapper">
          <Link
            style={{
              width: "100%",
            }}
            onClick={(e) => e.stopPropagation()}
            to={`/explore`}
          >
            <div className="explore-search-input">
              <input placeholder={texts[0]} type="text" name="search" />
            </div>
          </Link>
          <div className="explore-search-icon">
            <ICON_SEARCH />
          </div>
        </div>
      </div>
      <div className="Home-header-wrapper">
        <h2 className="profile-header">{texts[1]}</h2>
      </div>
      {user ? (
        <div>
          <div className="profile-wrapper">
            <div className="profile-details-wrapper">
              <div className="profile-details-box">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 30,
                  }}
                >
                  <div className="settings-question">
                    <p className="settings-question-title">{texts[2]}</p>
                    <div
                      className="settings-question-option"
                      onClick={() =>
                        props.history.push(`/settings/${userParam}/car-edit`)
                      }
                    >
                      <div className="name-label">
                        {surveyData ? surveyData.name : texts[3]}
                      </div>
                      <div className="settings-front-icon">
                        <ICON_ARROWFRONT />
                      </div>
                    </div>
                  </div>
                  <div className="settings-question">
                    <p className="settings-question-title">{texts[4]}</p>
                    <div
                      className="settings-question-option"
                      onClick={() =>
                        props.history.push(
                          `/settings/${userParam}/profile-edit`
                        )
                      }
                    >
                      <div className="name-label">{user.name}</div>
                      <div className="settings-front-icon">
                        <ICON_ARROWFRONT />
                      </div>
                    </div>
                  </div>
                  <div
                    className="settings-question-option"
                    style={{
                      paddingTop: "0",
                      paddingBottom: "0",
                      paddingLeft: 0,
                    }}
                  >

                    <ReactFlagsSelect
                      selected={getFlagCode()}
                      onSelect={(code) => {
                        localStorage.setItem("lang", code);
                        onSelectCountryCode(code);

                        const langCode = getLangCodeFromLangString(code);
                        actions.updateUser({
                          nativeLang: langCode,
                          _id: user._id,
                        });

                        window.location.reload(true);
                      }}
                      countries={country_list}
                      customLabels={country_labels}
                      placeholder="Select Language"
                    />
                  </div>
                  <div className="settings-question">
                    <p className="settings-question-title">{texts[5]}</p>
                    <div className="settings-question-option">
                      <a
                        href="https://myaccount.google.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="name-label">
                          {user.name.split(" ")[0]}
                        </div>
                        <div className="settings-front-icon">
                          <ICON_ARROWFRONT />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="settings-question">
                    <p className="settings-question-title"></p>
                    <div className="settings-question-option">
                      <a
                        href="https://docs.google.com/forms/d/1v91vwEmFwN0idylrob7X4jumzUq_FWKIGL76HIe_tzs/edit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="name-label">{texts[6]}</div>
                        <div className="settings-front-icon">
                          <ICON_ARROWFRONT />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="settings-question"
                    style={{ marginTop: "-20px" }}
                  >
                    <p className="settings-question-title"></p>
                    <div className="settings-question-option">
                      <div className="name-label">{texts[7]}</div>
                    </div>
                  </div>
                  <div
                    className="settings-question"
                    style={{ marginTop: "-20px" }}
                  >
                    <p className="settings-question-title"></p>
                    <div className="settings-question-option">
                      <div className="name-label">{texts[8]}</div>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <div className="settings-logout">
                      <a href="#logout" onClick={actions.logout}>
                        <ICON_LOGOUT />
                        {texts[9]}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-wrapper">
          <Loader />{" "}
        </div>
      )}
    </div>
  );
};

export default withRouter(Settings);
