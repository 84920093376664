import React, { useEffect } from "react";
import { useSearchCarvibes } from "../../hooks/useSearchCarvibes";
import { useLocalization } from "../../hooks/useLocalization";

const CategorySelector = ({ title, selectedCategories, onChange }) => {
  const { categories } = useSearchCarvibes();

  const { translatedTexts, onUpdateTexts } = useLocalization([]);

  useEffect(() => {
    if (categories.length > 0) {
      onUpdateTexts(categories.map((item) => item.content));
    }
  }, [categories]);

  return (
    <div style={{ marginBottom: 30 }}>
      <div>
        <p
          style={{
            marginBottom: "10px",
            marginTop: "15px",
            color: "#3E3E3E",
            fontSize: "0.85em",
          }}
        >
          {title}
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {categories.map((item, index) => {
          const selected = selectedCategories.includes(item.id);
          return (
            <div
              key={index}
              onClick={() => {
                if (selected) {
                  onChange(selectedCategories.filter((a) => a !== item.id));
                } else {
                  onChange([...selectedCategories, item.id]);
                }
              }}
              style={{
                cursor: "pointer",
                width: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <input
                type="checkbox"
                checked={selectedCategories.includes(item.id)}
                onChange={() => {}}
              />
              <p style={{ fontSize: 12, color: "#3E3E3E" }}>
                {translatedTexts[index] ? translatedTexts[index] : item.content}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategorySelector;
