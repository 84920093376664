import React, { useEffect, useContext, useState } from "react";
import { StoreContext } from "../../../store/store";
import "./style.scss";
import Loading from "../../loading";
import TableHeaderItem from '../../Admin/components/TableHeaderItem';
import TableItem from '../../Admin/components/TableItem';
import { getDatetimeFormat } from "../../../utils/common";
import dayjs from "dayjs";
import Avatar from "react-avatar";

const ManagementHistory = () => {
  const { state, actions } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentToken = localStorage.getItem("carvibestoken");
    if (currentToken && currentToken.length > 0) {
      actions.getLogs();
    } else {
      window.location = "/admin-login";
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);


  const tableHeader = () => {
    return (
      <div className="admin-carvibes-cell-title" style={{
        flexDirection: "row",
        height: "40px",
        backgroundColor: "#F7FAFC",

      }}>
        <TableHeaderItem title="処理日時" width="160px" />
        <TableHeaderItem title="投稿日時" width="160px" />
        <TableHeaderItem title="ユーザー名" width="160px" />
        <TableHeaderItem title="ユーザーID" width="160px" />
        <TableHeaderItem title="事案経緯" width="200px" />
        <TableHeaderItem title="対応カテゴリ" width="160px" />
      </div>
    );
  }

  if (loading) return Loading();

  return (
    <div className="admin-wrapper" style={{
      backgroundColor: "#fff"
    }}>
      <div className="admin-header-wrapper">
        <h2 className="admin-title">管理履歴</h2>
      </div>
      <div className="admin-content-wrapper">
        {tableHeader()}
        <div>
          {state.logs.length > 0 ? (
            state.logs.map((t) => {
              return (
                <div className="admin-carvibes-cell" key={t._id}>
                  <TableItem title={dayjs(t.createdAt).format(getDatetimeFormat("JP"))} width="160px" />
                  <TableItem title={t.carvibesCreatedDate ? dayjs(t.carvibesCreatedDate).format(getDatetimeFormat("JP")) : "---"} width="160px" />
                  <div className="card-userPic-wrapper" style={{
                    width: "160px",
                    flexBasis: "unset",
                    marginRight: "0",
                  }}>
                    <Avatar
                      name={t.username}
                      src={t.userImg}
                      size={32}
                      round={true}
                    ></Avatar>
                    <TableItem title={t.username} fontSize="14px" />
                  </div>
                  <TableItem title={t.user} width="160px" />
                  <TableItem title={t.content} width="200px" />
                  <TableItem title={t.category} width="160px" />
                </div>
              );
            })
          ) : (
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              width: "100%",
            }}>
              <span style={{
                fontSize: "16px",
                fontWeight: "400",
              }}>登録されたユーザーがいません。</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagementHistory;
