import React from "react";
import "./style.scss";
const ChatRoomTabs = ({ tabType, setTabType, unreadCount }) => {
  const allTabClassName =
    tabType === "all" ? "Chat-room-tabs-active" : "Chat-room-tabs-inactive";
  const unreadTabClassName =
    tabType === "unread" ? "Chat-room-tabs-active" : "Chat-room-tabs-inactive";
  return (
    <div className="Chat-room-tabs">
      <div
        className={allTabClassName}
        onClick={() => {
          setTabType("all");
        }}
      >
        すべて
      </div>
      <div
        className={unreadTabClassName}
        onClick={() => {
          setTabType("unread");
        }}
      >
        未読{unreadCount > 0 ? `（${unreadCount}）` : ""}
      </div>
    </div>
  );
};

export default ChatRoomTabs;
