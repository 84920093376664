import moment from "moment";
import React, { useState } from "react";
import Avatar from "react-avatar";

const ChatIncomingMessage = ({ message, user }) => {
  const [popUpImageSrc, setPopUpImageSrc] = useState("");
  const messageContent = message.message;

  const imageClassName = !!messageContent.messageText
    ? "image-incoming-combine"
    : "image-incoming-only";

  const messageClassName = !!messageContent.imageUrl
    ? "incoming-message-combine"
    : "incoming-message-only";
  return (
    <div className="Chat-incoming-message-container">
      <Avatar
        name={user.name}
        src={user.profileImg}
        size={50}
        round={true}
        style={{ marginBottom: 18 }}
      />
      <div className="Chat-incoming-message-item-container">
        {messageContent.imageUrl && (
          <img
            src={messageContent.imageUrl}
            alt="chat-image"
            className={`Chat-message-image ${imageClassName}`}
            onClick={() =>setPopUpImageSrc(messageContent.imageUrl)}
          />
        )}
        {messageContent.messageText && (
          <div
            className={`Chat-incoming-message-text-container ${messageClassName}`}
          >
            <p>{messageContent.messageText}</p>
          </div>
        )}
        <p
          style={{
            marginRight: "auto",
            fontSize: 12,
            color: "#333",
            marginTop: 6,
          }}
        >
          {moment(message.updatedAt).format("hh:mm A")}
        </p>
      </div>

      {popUpImageSrc !== "" ? (
        <div
          onClick={() => setPopUpImageSrc("")}
          className="chat-image-modal-container "
        >
          <div
            id="imageCarouselModal"
            className="chat-image-modal "
            onClick={(e) => e.stopPropagation()}
          >
            <img
              alt="chat "
              src={popUpImageSrc}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChatIncomingMessage;
