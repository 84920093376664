export const getChatRoomsKey = () => {
  return ["chat", "rooms"];
};

export const getChatConversationKey = (roomId) => {
  return ["chat", "conversation", roomId];
};

export const getChatRoomDetailKey = (roomId) => {
  return ["chat", "room", roomId];
};

export const getChatRecentConversationKey = () => {
  return ["chat", "recent-conversation"];
};

export const getChatSearchUserKey = () => {
  return ["chat", "search-user"];
};
