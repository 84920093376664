import React, { useEffect, useState, useContext } from "react";
import "../Profile/style.scss";
import "../ProfileEdit/style.scss";
import { ICON_UPLOAD, ICON_ARROWBACK } from "../../Icons";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../store/store";
import Loader from "../Loader";
import { API_URL } from "../../config";
import axios from "../../axiosLib";
import { supportedImageFiletypes } from "../../utils/common";
import Select from "react-select";
import "./style.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SURVEY_OPTIONS } from "./survey-constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCreateSurvey, useGetSurvey } from "../../client-api/survey";
import { toast } from "react-toastify";

const SurveySchema = Yup.object().shape({
  name: Yup.string().required("必須項目です"),
  imageUrl: Yup.string().required("必須項目です"),
  mileage: Yup.string().required("必須項目です"),
  yearType: Yup.string().required("必須項目です"),
  repairHistory: Yup.string().required("必須項目です"),
  companionTime: Yup.string().required("必須項目です"),
  reasonForChooseCar: Yup.string(),
  nextCarType: Yup.string().required("必須項目です"),
  carMeansToYou: Yup.string().required("必須項目です"),
  annualIncome: Yup.string().required("必須項目です"),
  howMuchSpendOnCar: Yup.string().required("必須項目です"),
  specificGenresWillSpendingOn: Yup.string().required("必須項目です"),
});

const getInitData = (data) => {
  return {
    name: data?.name ?? "",
    imageUrl: data?.imageUrl ?? "",
    mileage: data?.mileage ?? "",
    yearType: data?.yearType ?? "",
    repairHistory: data?.repairHistory ?? "",
    companionTime: data?.companionTime ?? "",
    reasonForChooseCar: data?.reasonForChooseCar ?? "",
    nextCarType: data?.nextCarType ?? "",
    carMeansToYou: data?.carMeansToYou ?? "",
    annualIncome: data?.annualIncome ?? "",
    howMuchSpendOnCar: data?.howMuchSpendOnCar ?? "",
    specificGenresWillSpendingOn: data?.specificGenresWillSpendingOn ?? "",
  };
};

const CarEdit = (props) => {
  const { state, actions } = useContext(StoreContext);
  const { user } = state;
  const [uploadingImage, setUploadingImage] = useState(false);

  const { data: surveyData, isLoading: iSurveyLoading } = useGetSurvey(
    user._id
  );

  const { mutateAsync: createSurvey, isLoading: isCreating } = useCreateSurvey(
    user._id
  );

  const formik = useFormik({
    initialValues: getInitData(surveyData),
    validationSchema: SurveySchema,
    validateOnMount: false,
    onSubmit: async (values) => {
      await createSurvey(values);
      props.history.push(`/settings/${props.match.params.userid}`);
    },
  });

  useEffect(() => {
    if (surveyData) {
      formik.setValues({
        ...formik.values,
        ...surveyData,
      });
    }
  }, [surveyData]);

  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (!(currentToken && currentToken.length > 0)) {
      window.location = "/login";
    }
    window.scrollTo(0, 0);
    actions.getUser(props.match.params.userid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.userid]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const uploadCarImage = (file, type) => {
    setUploadingImage(true);
    let bodyFormData = new FormData();
    bodyFormData.append("image", file);
    axios
      .post(`${API_URL}/user/upload`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("carvibestoken")}`,
        },
      })
      .then((res) => {
        formik.setFieldValue("imageUrl", res.data.imageUrl);
        setUploadingImage(false);
      })
      .catch((err) => {
        setUploadingImage(false);
        actions.alert("error uploading image");
      });
  };

  const changeCarAvatar = () => {
    let file = document.getElementById("caravatar").files[0];
    uploadCarImage(file);
  };

  const selectComponent = (survey) => {
    const selectedOption = survey.options.find(
      (option) => option.value === formik.values[survey.value]
    );
    const error = formik.errors[survey.value];
    return (
      <div key={survey.title} className="edit-input-wrap">
        <div className="edit-input-content">
          <label>
            {survey.title}{" "}
            {survey.required == false ? (
              ""
            ) : (
              <sup style={{ color: "red" }}>*</sup>
            )}
          </label>
          <Select
            className="edit-input"
            classNamePrefix="select"
            value={selectedOption}
            onChange={(e) => formik.setFieldValue(survey.value, e.value)}
            name={survey.title}
            options={survey.options}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                height: 48,
                background: "rgb(245, 248, 250)",
                borderRadius: 10,
                borderColor: "rgba(245, 248, 250, 0.0)",
              }),
            }}
          />
          {error ? <p style={{ color: "red" }}>{error}</p> : null}
        </div>
      </div>
    );
  };

  const areaComponent = (survey) => {
    const error = formik.errors[survey.value];
    return (
      <div key={survey.title} className="edit-input-wrap">
        <div className="edit-input-content">
          <label>
            {survey.title} <sup style={{ color: "red" }}>*</sup>
          </label>
          <textarea
            value={formik.values[survey.value]}
            onChange={(e) => {
              formik.setFieldValue(survey.value, e.target.value);
            }}
            type="text"
            name={survey.title}
            className="edit-input"
          />
          {error ? <p style={{ color: "red" }}>{error}</p> : null}
        </div>
      </div>
    );
  };

  const dateComponent = (survey) => {
    const selectedDate = formik.values[survey.value]
      ? new Date(formik.values[survey.value])
      : null;
    const error = formik.errors[survey.value];

    return (
      <div key={survey.title} className="edit-input-wrap">
        <div className="edit-input-content">
          <label>
            {survey.title} <sup style={{ color: "red" }}>*</sup>
          </label>
          <DatePicker
            selected={selectedDate}
            onChange={(e) => formik.setFieldValue(survey.value, e)}
            dateFormat="yyyy"
            showYearPicker={true}
            className="ignore-input-border"
            style={{ width: "100%", borderColor: "rgba(245, 248, 250, 0.0)" }}
          />
          {error ? <p style={{ color: "red" }}>{error}</p> : null}
        </div>
      </div>
    );
  };

  const inputComponent = (survey) => {
    const error = formik.errors[survey.value];

    return (
      <div key={survey.title} className="edit-input-wrap">
        <div className="edit-input-content">
          <label>
            {survey.title} <sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            value={formik.values[survey.value]}
            onChange={(e) => formik.setFieldValue(survey.value, e.target.value)}
            type="text"
            name="name"
            className="edit-input"
          />
          {error ? <p style={{ color: "red" }}>{error}</p> : null}
        </div>
      </div>
    );
  };

  const onSurveySave = () => {
    if (isCreating || iSurveyLoading || uploadingImage) {
      return;
    }
    if (formik.isValid) {
      formik.submitForm();
    } else {
      const errorMsg = !imageUrl
        ? "アイコン設定してください“?"
        : "必須項目を入力してください?";
      toast(errorMsg, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const imageUrl = formik.values.imageUrl;

  return (
    <div
      className="profile-edit-root"
      style={{
        backgroundImage: `url('/bg.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPositionX: "center",
        height: "100%",
      }}
    >
      {user ? (
        <div>
          <div className="modal-edit">
            <div onClick={(e) => handleModalClick(e)} className="modal-content">
              <div className="modal-header">
                <div className="modal-closeIcon">
                  <div
                    onClick={() => props.history.goBack()}
                    className="modal-closeIcon-wrap"
                  >
                    <ICON_ARROWBACK />
                  </div>
                </div>
                <p
                  className="modal-title"
                  style={{
                    fontSize: "0.8em",
                  }}
                >
                  {"愛車情報の編集"}
                </p>

                <div className="save-modal-wrapper">
                  <div
                    onClick={(e) => onSurveySave()}
                    className="save-modal-btn"
                  >
                    {"保存"}
                  </div>
                </div>
              </div>

              <div
                className="modal-body"
                style={{
                  height: "85%",
                  overflowY: "scroll",
                  display: "block",
                }}
              >
                <div className="sp-header">
                  <div
                    className="modal-closeIcon-wrap"
                    onClick={() => props.history.goBack()}
                  >
                    <ICON_ARROWBACK color="#ffffff" />
                  </div>
                  <p className="modal-title">{"愛車情報の編集"}</p>
                </div>

                <div className="modal-profile-pic-wrapper">
                  <p>{"愛車のアイコン"}</p>
                  <div className="modal-profile-pic">
                    <div className="modal-back-pic">
                      <img
                        src={
                          uploadingImage
                            ? "https://i.imgur.com/62jOROc.gif"
                            : imageUrl
                        }
                        alt=""
                      />
                      <div>
                        <ICON_UPLOAD />
                        <input
                          onChange={() => changeCarAvatar()}
                          title=" "
                          id="caravatar"
                          style={{ opacity: "0" }}
                          accept={supportedImageFiletypes.join(", ")}
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <form className="edit-form" style={{ paddingBottom: "100px" }}>
                  {SURVEY_OPTIONS.map((survey) => {
                    if (survey.type === "select") {
                      return selectComponent(survey);
                    }
                    if (survey.type === "textarea") {
                      return areaComponent(survey);
                    }
                    if (survey.type === "date") {
                      return dateComponent(survey);
                    }
                    if (survey.type === "input") {
                      return inputComponent(survey);
                    }
                    return null;
                  })}
                  <div
                    className="sp-save-modal-wrapper"
                    style={{
                      margin: "20px auto 0 auto"
                    }}
                  >
                    <div
                      onClick={(e) => onSurveySave()}
                      className="save-modal-btn"
                      style={{ color: "white" }}
                    >
                      {"保存"}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-wrapper">
          <Loader />{" "}
        </div>
      )}
    </div>
  );
};

export default withRouter(CarEdit);
