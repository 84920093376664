import React from 'react';

class InformModal extends React.Component {
    render() {
        const { isOpen, title, onClose, modalWidth, textPadding } = this.props;

        if (!isOpen) {
            return null;
        }

        return (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1000,
                }}
            >
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                ></div>
                <div
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "10px",
                        width: modalWidth ?? "300px",
                        textAlign: "center",
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <p style={{ padding: textPadding }}>{title}</p>
                    <div
                        onClick={onClose}
                        style={{
                            color: "white",
                            padding: "10px 20px",
                            marginTop: "15px",
                            background: "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                    >
                        Close
                    </div>
                </div>
            </div>
        );
    }
}

export default InformModal;
