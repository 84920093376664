export const supportedImageFiletypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
];

export const getDatetimeFormat = (countryCode) => {
  return countryCode.toLowerCase() === "jp"
    ? "YYYY/MM/DD hh:mm"
    : "DD/MM/YYYY hh:mm";
};

export const checkIfTextIsJa = (text) => {
  // Regular expression to match Japanese characters
  const jpRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;
  return jpRegex.test(text);
}

export const getLangCodeFromLangString = (language) => {
  const mapping = {
    US: 'en-US',
    GB: 'en-GB',
    ES: 'es',
    FR: 'fr',
    DE: 'de',
    IT: 'it',
    PT: 'pt',
    CN: 'zh',
    JP: 'ja'
  };

  return mapping[language] || 'ja';
}

export const getFlagCodeFromLangCode = (langCode) => {
  const mapping = {
    'en-US': 'US',
    'en-GB': 'GB',
    'es': 'ES',
    'fr': 'FR',
    'de': 'DE',
    'it': 'IT',
    'pt': 'PT',
    'zh': 'CN',
    'ja': 'JP'
  };

  return mapping[langCode] || 'JP';
}


export const extractLinks = (text) => {
  const linkRegex = /(https?:\/\/[^\s]+)/g;
  return text.match(linkRegex) || [];
};

export const replaceLinksWithPlaceholders = (text, links) => {
  let modifiedText = text;
  const placeholders = [];

  links.forEach((link, index) => {
    const placeholder = `__LINK_${index}__`;
    modifiedText = modifiedText.replace(link, placeholder);
    placeholders.push(placeholder);
  });

  return { modifiedText, placeholders };
};

export const replacePlaceholdersWithLinks = (text, links, placeholders) => {
  let result = text;
  placeholders.forEach((placeholder, index) => {
    result = result.replace(placeholder, links[index]);
  });

  console.log(result);
  return result;
};