import React, { useContext, useEffect } from "react";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import "./style.scss";
import { Redirect } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { country_labels, country_list } from "../../constants/language";
import { useLocalization } from "../../hooks/useLocalization";
import { useGetUserCnt } from "../../hooks/useGetUserCnt";
import { StoreContext } from "../../store/store";
import { ENABLE_FB_LOGIN } from "../../constants/featureFlag";
import { Link } from "react-router-dom";

const LOGIN_SCREEN_TEXT = [
  "あなたの愛車でココロ踊る体験を。",
  `現在、世界中のクルマ好き XXX 人 が全力応援中!!`,
  `現在、世界中のクルマ XXX 台 が全力稼働中!!`,
  "ログインして始めましょう！",
  "Googleアカウントでログイン/登録を行います。",
  "認証できない場合はお問合せください。",
  "運営会社",
  "利用規約",
  "プライバシーポリシー",
  "お問合せ",
  "運営会社",
];

const LoginPage = () => {
  const { state, actions } = useContext(StoreContext);
  const { count } = useGetUserCnt();

  const { translatedTexts, countryCode, onSelectCountryCode } =
    useLocalization(LOGIN_SCREEN_TEXT);

  const onGoogleLogin = (e) => {
    e.preventDefault();
    actions.googleLogin();
  };

  const onFBLogin = (e) => {
    e.preventDefault();
    actions.fbLogin();
  };

  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (currentToken && currentToken.length > 0) {
      window.location = "/home";
    }
  }, []);

  return (
    <div className={"container"}>
      {state.loggedin && <Redirect to="/home" />}
      <div className={"container2"}>
        <div className='login-language-selector'>
          <ReactFlagsSelect
            className="flag-select"
            selected={countryCode}
            onSelect={(code) => {
              localStorage.setItem("lang", code);
              onSelectCountryCode(code);
            }}
            countries={country_list}
            customLabels={country_labels}
            placeholder="Select Language"
          />
          <p>Please select your native language</p>
        </div>
        <div className={"feature-card"}>
          <img src="/logo_login.png" alt="logo" style={{ width: 250 }} />
          <h2 className={"text"}>{translatedTexts[0]}</h2>
          <div className="announcement">
            <img src="/announce.png" style={{ height: 20 }} alt="car" />
            <span>{translatedTexts[1].replace("XXX", count)}</span>
          </div>
          <div className="announcement">
            <img src="/car.png" style={{ height: 20 }} alt="car" />
            <span>{translatedTexts[2].replace("XXX", count)}</span>
          </div>
        </div>
        <div className={"container3"}>
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              overflowX: "hidden",
              height: "100%",
            }}
          >
            <div className="grid">
              <img className="animation_up1" src="/carvibes_gif/up_1.gif" alt="" />
              <img className="animation_up2" src="/carvibes_gif/up_2.gif" alt="" />
              <img className="animation_up3" src="/carvibes_gif/up_3.gif" alt="" />
            </div>
            <div
              className="grid"
              style={{
                position: "absolute",
                top: 200,
              }}
            >
              <img
                className="animation_down1"
                src="/carvibes_gif/down_1.gif"
                alt=""
              />
              <img
                className="animation_down2"
                src="/carvibes_gif/down_2.gif"
                alt=""
              />
              <img
                className="animation_down3"
                src="/carvibes_gif/down_3.gif"
                alt=""
              />
            </div>
            <div
              className="grid"
              style={{
                position: "absolute",
                top: 400,
              }}
            >
              <img className="animation_up1" src="/carvibes_gif/up_4.gif" alt="" />
              <img className="animation_up2" src="/carvibes_gif/up_5.gif" alt="" />
              <img className="animation_up3" src="/carvibes_gif/up_6.gif" alt="" />
            </div>
            <div
              className="grid"
              style={{
                position: "absolute",
                top: 600,
              }}
            >
              <img
                className="animation_down1"
                src="/carvibes_gif/down_4.gif"
                alt=""
              />
              <img
                className="animation_down2"
                src="/carvibes_gif/down_2.gif"
                alt=""
              />
              <img
                className="animation_down3"
                src="/carvibes_gif/down_1.gif"
                alt=""
              />
            </div>
            <div
              className="grid"
              style={{
                position: "absolute",
                top: 800,
              }}
            >
              <img className="animation_up1" src="/carvibes_gif/up_1.gif" alt="" />
              <img className="animation_up2" src="/carvibes_gif/up_3.gif" alt="" />
              <img className="animation_up3" src="/carvibes_gif/up_6.gif" alt="" />
            </div>
            <div
              className="grid"
              style={{
                position: "absolute",
                top: 1000,
              }}
            >
              <img
                className="animation_down1"
                src="/carvibes_gif/down_2.gif"
                alt=""
              />
              <img
                className="animation_down2"
                src="/carvibes_gif/down_3.gif"
                alt=""
              />
              <img
                className="animation_down3"
                src="/carvibes_gif/down_1.gif"
                alt=""
              />
            </div>
            <div
              className="grid"
              style={{
                position: "absolute",
                top: 1200,
              }}
            >
              <img className="animation_up1" src="/carvibes_gif/up_2.gif" alt="" />
              <img className="animation_up2" src="/carvibes_gif/up_5.gif" alt="" />
              <img className="animation_up3" src="/carvibes_gif/up_1.gif" alt="" />
            </div>
          </div>
          <div
            style={{
              background:
                "linear-gradient(90deg, rgba(57, 90, 55, 0.8) 0%, rgba(43, 71, 115, 0.8) 100%)",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: -1,
            }}
          />
          <div className={"feature-card1"}>
            <h2 className={"text03"}>{translatedTexts[3]}</h2>
            {ENABLE_FB_LOGIN && (
              <div
                className={"facebook-login-btn-wrap button-active"}
                style={{ marginBottom: 16 }}
              >
                <FaFacebook />
                <span
                  onClick={(e) => {
                    onFBLogin(e);
                  }}
                  type="submit"
                  form="loginForm"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                >
                  Log in with Facebook
                </span>
              </div>
            )}
            <div className={"google-login-btn-wrap button-active"}>
              <FaGoogle />
              <span
                onClick={(e) => {
                  onGoogleLogin(e);
                }}
                type="submit"
                form="loginForm"
                style={{ cursor: "pointer", marginLeft: 10 }}
              >
                Log in with Google
              </span>
            </div>

            <span className={"text07"}>
              <span>{translatedTexts[4]}</span>
              <br></br>
              <span>{translatedTexts[5]}</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <footer className={"footer"}>
        <div className={"container4"}>
          <div className={"container5"}>
            <span className="copyright">
              <Link to={`/terms-of-use`}>
                {translatedTexts[7]}
              </Link>
            </span>
            <span className="copyright">
              <Link to={`/privacy-policy`}>
                {translatedTexts[8]}
              </Link>
            </span>
            <span className="copyright">
              <a
                href="https://docs.google.com/forms/d/1v91vwEmFwN0idylrob7X4jumzUq_FWKIGL76HIe_tzs/formrestricted"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translatedTexts[9]}
              </a>
            </span>

            <span className="copyright">
              <Link to={`/company`}>
                {translatedTexts[10]}
              </Link>

            </span>
          </div>
        </div>
        <span className="copyright">
          COPYRIGHT 2024 T&amp;F ASSET MANAGEMENT INC. ALL RIGHTS RESERVED.
        </span>
      </footer>
    </div>
  );
};

export default LoginPage;
