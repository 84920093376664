import React, { useEffect, useContext } from "react";
import "./style.scss";
import { withRouter, Link } from "react-router-dom";
import { StoreContext } from "../../store/store";
import Loader from "../Loader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import UserAvatar from "../UserAvatar";

const Feed = (props) => {
  const { state, actions } = useContext(StoreContext);

  const { account, trends, suggestions, session } = state;
  // const userParam = props.match.params.username
  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (!(currentToken && currentToken.length > 0)) {
      window.location = "/login";
    }
    actions.getTrend();
    if (session) {
      actions.whoToFollow();
    }
  }, []);

  const goToUser = (id) => {
    props.history.push(`/profile/${id}`);
  };

  const followUser = (e, id) => {
    e.stopPropagation();
    actions.followUser({ id });
  };

  return (
    <div className="feed-wrapper">
      <div className="feed-trending-card">
        <h3 className="feed-card-header">Trending</h3>
        {trends.length > 0 ? (
          trends.slice(0, 3).map((t, i) => {
            return (
              <div
                onClick={() => props.history.push("/explore")}
                key={t._id}
                className="feed-card-trend"
              >
                <div>{i + 1} · Trending</div>
                <div>{t.content}</div>
              </div>
            );
          })
        ) : (
          <Loader />
        )}
        <div
          onClick={() => props.history.push(`/explore`)}
          className="feed-more"
        >
          Show more
        </div>
      </div>
      {account ? (
        <div className="feed-trending-card">
          <h3 className="feed-card-header">
            Who to follow {suggestions.length}
          </h3>
          {suggestions.length > 0 ? (
            suggestions
              .filter((item) => item._id !== account._id)
              .map((s) => {
                return (
                  <div key={s._id} className="feed-card-trend">
                    <div
                      onClick={() => goToUser(s._id)}
                      className="sugg-result-wapper"
                    >
                      <Link
                        to={`/profile/${s._id}`}
                        className="search-userPic-wrapper"
                      >
                        <UserAvatar user={s} />
                      </Link>
                      <div className="search-user-details">
                        <div className="search-user-warp">
                          <div className="search-user-info">
                            <div className="search-user-name">{s.name}</div>
                            <div className="search-user-username">{s.name}</div>
                          </div>
                          <div
                            onClick={(e) => followUser(e, s._id)}
                            className={
                              account.following.includes(s._id)
                                ? "follow-btn-wrap unfollow-switch"
                                : "follow-btn-wrap"
                            }
                          >
                            <span style={{ lineHeight: "0.8" }}>
                              <span>
                                {account.following.includes(s._id)
                                  ? "Following"
                                  : "Follow"}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <Loader />
          )}
          <div className="feed-more">{/* Show more */}</div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(Feed);
