import React from "react";
import "./style.scss";
import { ICON_HASH, ICON_SEARCH } from "../../Icons";
const ChatRoomSearch = ({ search, setSearch }) => {
  return (
    <div className="Chat-room-search">
      <input
        type="text"
        placeholder="メッセージを検索"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <ICON_HASH />
    </div>
  );
};
export default ChatRoomSearch;
