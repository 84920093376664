import React, { useEffect, useState, useContext, useRef } from "react";
import { StoreContext } from "../../../store/store";
import { Link, withRouter } from "react-router-dom";
import "./style.scss";
import {
  ICON_ADMIN_HOME,
  ICON_ADMIN_USER_LIST,
  ICON_NEW_USER_HISTORY,
  ICON_NOTIFICATION,
  ICON_MANAGE_HISTORY,
} from "../../../Icons";
import axios from "../../../axiosLib";
import { API_URL } from "../../../config";
import { country_lng } from "../../../constants/language";

const AdminNav = ({ history }) => {
  const { state, actions } = useContext(StoreContext);
  const [styleBody] = useState(false);

  const [texts, setTexts] = useState([
    "ダッシュボード",
    "検索",
    "投稿",
    "マイページ",
    "設定",
    "投稿しました",
    "新規投稿",
    "プレビュー",
    "次へ",
    "投稿する",
    "キャプション",
    "愛車について自由に記録してください。",
    "タグ",
    "カテゴリ（任意）",
  ]);

  const updateTexts = (code) => {
    axios
      .post(
        `${API_URL}/translate`,
        {
          texts,
          destination: country_lng[code],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("carvibestoken")}`,
          },
        }
      )
      .then((res) => {
        let resp = res.data;
        const newTexts = resp.map((r) => r.text.replace(/ *\([^)]*\) */g, ""));
        setTexts(newTexts);
      })
      .catch((err) => actions.alert("error uploading image"));
  };

  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (currentToken === null || currentToken === undefined || currentToken.length === 0) {
      const path = history.location.pathname;
      if (path !== "/admin-login") {
        window.location = "/admin-login";
      }
    }
  }, []);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      document.getElementsByTagName("body")[0].style.cssText =
        styleBody && "overflow-y: hidden; margin-right: 17px";
    }
  }, [styleBody]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  useEffect(() => {
    let ran = false;
    history.listen((location, action) => {
      state.account == null
        ? actions.verifyToken("get account")
        : actions.verifyToken();
    });
    if (localStorage.getItem("lang") !== "jp")
      updateTexts(localStorage.getItem("lang"));
    !ran && state.account == null
      ? actions.verifyToken("get account")
      : actions.verifyToken();
  }, []);

  const path = history.location.pathname;

  const NavItem = (path, link, icon, text) => {
    let isSelected = path === link;
    if (path.includes("/admin/user-list")) {
      isSelected = link.includes("/admin/user-list");
    }

    return (
      <Link to={link} className={
        text === "投稿管理" ? "Nav-link btn-hide" : "Nav-link"
      }>
        <div
          className={
            isSelected
              ? "Nav-item-hover-admin active-Nav-admin"
              : "Nav-item-hover-admin"
          }
        >
          {icon}
          <div className={
            isSelected
              ? "Nav-item-admin btn-hide"
              : "Nav-item-admin-text btn-hide"
          }>{text}</div>
        </div>
      </Link>
    );
  }

  return (
    <>
      <div className="Nav-component">
        <div className="Nav-width">
          <div className="Nav">
            <div className="Nav-Content" style={{
              paddingLeft: "12px",
              paddingRight: "12px",
            }}>
              <nav className="Nav-wrapper">
                {state.account !== undefined && state.account !== null && state.account.admin === false && history.push("/home")}
                <Link to={`/admin`} className="logo-wrapper btn-hide">
                  <img width={"100%"} src="/logo.png" alt="Logo" />
                </Link>
                <div className="navicons">
                  {NavItem(path, "/admin", <ICON_ADMIN_HOME color={
                    path === "/admin" || path === "/admin/"
                      ? "#fff"
                      : "#285E61"
                  } />, "投稿管理")}
                  <Link className="Nav-link btn-show" to={`/admin`}>
                    <div
                      className={
                        path === "/admin" ? "Nav-item-hover" : "Nav-item-hover"
                      }
                    >
                      <img
                        src="/small.png"
                        alt="small logo"
                        style={{
                          height: "20px",
                        }}
                      />
                    </div>
                  </Link>
                  {NavItem(path, "/admin/user-list", <ICON_ADMIN_USER_LIST color={
                    path.includes("/admin/user-list")
                      ? "#fff"
                      : "#285E61"
                  } />, "ユーザー一覧")}
                  {NavItem(path, "/admin/new-user-history", <ICON_NEW_USER_HISTORY className="svg-icon-admin" color={
                    path === "/admin/new-user-history" || path === "/admin/new-user-history/"
                      ? "#fff"
                      : "#285E61"
                  } />, "新規ユーザー履歴")}
                  {NavItem(path, "/admin/send-notification", <ICON_NOTIFICATION color={
                    path === "/admin/send-notification" || path === "/admin/send-notification/"
                      ? "#fff"
                      : "#285E61"
                  } />, "通知送信")}
                  {NavItem(path, "/admin/manage-history", <ICON_MANAGE_HISTORY color={
                    path === "/admin/manage-history"
                      ? "#fff"
                      : "#285E61"
                  } />, "管理履歴")}
                  {/* {NavItem(path, "/admin/signup", <ICON_FEATHER style={
                    path === "/admin/signup"
                      ? { color: "#fff" }
                      : { color: "#285E61" }
                  } />, "新規登録")} */}
                </div>
              </nav>
              <div style={{ height: "100%" }}></div>
              <div
                className="Nav-item-logout" onClick={actions.logout}>
                <a href="#logout" >
                  ログアウト
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(AdminNav);
