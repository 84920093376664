import React, { useContext } from "react";
import "./style.scss";
import { StoreContext } from "../../store/store";

const FollowButton = ({ user, show = true }) => {
  const { state, actions } = useContext(StoreContext);
  const { account, session } = state;

  const followUser = (id) => {
    if (!session || !account) {
      actions.alert("Please Sign In");
      return;
    }
    actions.followUser({ id, userId: account._id });
  };
  const followed = !!user && (account.following ?? []).includes(user._id);
  if (!show) {
    return null;
  }
  if (user && account._id === user._id) {
    return null;
  }
  if (followed) {
    return (
      <button onClick={(e) => followUser(user._id)} className="unfollow-btn">
        フォロー解除
      </button>
    );
  }
  return (
    <button onClick={(e) => followUser(user._id)} className="follow-btn">
      フォロー
    </button>
  );
};

export default FollowButton;
