import { useQuery } from "react-query";
import { getSurvey } from "./queryKey";
import axiosLib from "../../axiosLib";
import { API_URL } from "../../config";

export const getSurveyRequest = async () => {
  const response = await axiosLib.get(`${API_URL}/survey`);
  if (response.data.success) {
    if (response.data.surveys.length > 0) return response.data.surveys[0];
  }
  return null;
};

export const useGetSurvey = (userId) => {
  return useQuery(getSurvey(userId), async () => getSurveyRequest(), {
    enabled: !!userId,
  });
};
