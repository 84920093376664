import React, { useContext, useState } from "react";
import { StoreContext } from "../../store/store";
import Loading from "../loading";
import { withRouter } from "react-router-dom";
import {
  useGetChatRecentConversation,
  useGetChatRooms,
} from "../../client-api";
import "./style.scss";
import ChatRooms from "./ChatRooms";
import ChatMessages from "./ChatMessages";
import { MediaQuery, useMediaQuery } from "../../hooks/useMediaQuery";

const Chat = () => {
  const { state, actions } = useContext(StoreContext);
  const { account } = state;
  const [currentRoom, setCurrentRoom] = useState(null);
  const isSM = useMediaQuery(MediaQuery.sm);

  const queryEnabled = !!account;
  const {
    data: rooms,
    error: roomsError,
    isLoading: roomsLoading,
  } = useGetChatRooms(queryEnabled);
  const {
    data: recentConversations,
    error: recentConversationError,
    isLoading: recentChatLoading,
  } = useGetChatRecentConversation(queryEnabled);
  const dataLoading = !rooms || !recentConversations;

  const loading = dataLoading || roomsLoading || recentChatLoading;

  if (loading) return Loading();

  return (
    <div className="Chat-container">
      {isSM ? (
        <ChatRooms
          account={account}
          rooms={rooms}
          currentRoom={currentRoom}
          setCurrentRoom={setCurrentRoom}
        />
      ) : (
        !currentRoom && (
          <ChatRooms
            account={account}
            rooms={rooms}
            currentRoom={currentRoom}
            setCurrentRoom={setCurrentRoom}
          />
        )
      )}
      {currentRoom && (
        <ChatMessages
          roomId={currentRoom}
          account={account}
          onBack={() => {
            setCurrentRoom(null);
          }}
        />
      )}
    </div>
  );
};

export default withRouter(Chat);
