//  name: Schema.Types.Mixed,
//     imageUrl: Schema.Types.Mixed,
//     mileage: Schema.Types.Mixed,
//     yearType: Schema.Types.Mixed,
//     repairHistory: Schema.Types.Mixed,
//     companionTime: Schema.Types.Mixed,
//     reasonForChooseCar: Schema.Types.Mixed,
//     nextCarType: Schema.Types.Mixed,
//     carMeansToYou: Schema.Types.Mixed,
//     specificGenresWillSpendingOn: Schema.Types.Mixed,
//     user: {
//       required: true,
//       type: Schema.Types.ObjectId,
//       ref: "User",
//     },

export const SURVEY_OPTIONS = [
  {
    title: "愛車名（ニックネーム）",
    value: "name",
    type: "input",
  },
  {
    title: "走行距離",
    value: "mileage",
    type: "select",
    options: [
      {
        label: "5,000km以下",
        value: "less-than-5k",
      },
      {
        label: "10,001-20,000km以下",
        value: "10k-to-20k",
      },
      {
        label: "20,001-30,000km以下",
        value: "20k-to-30k",
      },
      {
        label: "30,001-40,000km以下",
        value: "30k-to-40k",
      },
      {
        label: "40,001-50,000km以下",
        value: "40k-to-50k",
      },
      {
        label: "50,000-70,000km以下",
        value: "50k-to-70k",
      },
      {
        label: "70,001-90,000km以下",
        value: "70k-to-90k",
      },
      {
        label: "90,000km以上",
        value: "more-than-90k",
      },
    ],
  },
  {
    title: "年式",
    value: "yearType",
    type: "date",
    format: "yyyy",
  },
  {
    title: "修理歴",
    value: "repairHistory",
    type: "select",
    options: [
      {
        label: "ある",
        value: "yes",
      },
      {
        label: "なし",
        value: "no",
      },
    ],
  },
  {
    title: "今の愛車はいつまで相棒？",
    value: "companionTime",
    type: "select",
    options: [
      {
        label: "廃車になるまで、とことん付き合う",
        value: "scrape",
      },
      {
        label: "あと2-3年は相棒",
        value: "keep_2_3_years",
      },
      {
        label: "せめてあと5年ぐらいは相棒",
        value: "keep_at_least_5_years",
      },
      {
        label: "1年以内には手放すつもり",
        value: "get_rid_within_1_year",
      },
      {
        label: "条件が合うなら、いつ手放しても良い",
        value: "anytime_if_condition_match",
      },
    ],
  },
  {
    title: "何故、今の愛車に？",
    value: "reasonForChooseCar",
    type: "select",
    required: false,
    options: [
      {
        label: "デザインが気に入ってたから",
        value: "design",
      },
      {
        label: "とにかくリセールが魅力",
        value: "resale",
      },
      {
        label: "ずっと憧れていたクルマだった",
        value: "dream",
      },
      {
        label: "金額的にも購入できそうだった",
        value: "affordable",
      },
      {
        label: "スペック(馬力や燃費)、そして内外装含めたデザインに一惚れ",
        value: "spec_design",
      },
    ],
  },
  {
    title: "次狙うとしたら、どんな車？(※新たに追加したい&必須項目)",
    value: "nextCarType",
    type: "textarea",
    maxLength: 100,
  },
  {
    title: "あなたの職業について",
    value: "occupation",
    type: "select",
    options: [
      {
        label: "自営業（個人事業主またはフリーランス）",
        value: "self_employed",
      },
      {
        label: "企業経営者（創業者または起業家）",
        value: "entrepreneur",
      },
      {
        label: "企業経営陣（取締役及び執行役員を含む経営陣）",
        value: "executive",
      },
      {
        label: "自由業（アーティスト、クリエイター、YouTuberなど）",
        value: "freelancer",
      },
      {
        label: "会社員（正社員）",
        value: "full_time_employee",
      },
      {
        label: "公務員 （国家公務員または地方自治体含など）",
        value: "government_employee",
      },
      {
        label: "派遣",
        value: "dispatch",
      },
      {
        label: "フリーター",
        value: "freeter",
      },
      {
        label: "アルバイト",
        value: "part_time_job",
      },
      {
        label: "その他",
        value: "other",
      },
    ],
  },
  {
    title:
      "あなたの所得・年収はどのぐらい？※これもプルダウンで、選べるように。",
    value: "annualIncome",
    type: "select",
    options: [
      {
        label: "〜300万円",
        value: "less_than_300k",
      },
      {
        label: "300〜500万円",
        value: "300K_to_500K",
      },
      {
        label: "500〜700万円",
        value: "500k_to_700k",
      },
      {
        label: "700〜900万円",
        value: "700k_to_900k",
      },
      {
        label: "900〜1,100万円",
        value: "900k_to_1100k",
      },
      {
        label: "1,100〜1,500万円",
        value: "1100k_to_1500k",
      },
      {
        label: "2,000万円以上〜3,000万円",
        value: "2000k_to_3000k",
      },
      {
        label: "3,000万円以上〜5,000万円",
        value: "3000k_to_5000k",
      },
      {
        label: "5,000万円以上〜1億円",
        value: "5000k_to_1M",
      },
      {
        label: "1億円以上〜",
        value: "1M_or_more",
      },
    ],
  },
  {
    title: "あなたにとってクルマはどんな存在？",
    value: "carMeansToYou",
    type: "select",
    options: [
      {
        label: "大切な相棒",
        value: "companion",
      },
      {
        label: "人生の一部のような存在",
        value: "life_part",
      },
      {
        label: "恋人そのもの",
        value: "lover",
      },
      {
        label: "移動手段の足",
        value: "transportation",
      },
      {
        label: "動けば良いとしか思っていない",
        value: "just_move",
      },
      {
        label: "特に考えたこともない",
        value: "never_thought",
      },
      {
        label: "その他",
        value: "other",
      },
    ],
  },
  {
    title:
      "あなたが所有している車に対して、所有期間を通してどの程度の費用をかけますか？（※ ローン・駐車場・諸経費等は含まず）",
    value: "howMuchSpendOnCar",
    type: "select",
    options: [
      {
        label: "100万以下",
        value: "less_than_100k",
      },
      {
        label: "101万~300万以下",
        value: "101k_to_300k",
      },
      {
        label: "301万~500万以下",
        value: "300k_to_500k",
      },
      {
        label: "501万~上限なし",
        value: "500k_to_no_limit",
      },
    ],
  },
  {
    title: "具体的にどんなジャンルに費用をかけますか？",
    value: "specificGenresWillSpendingOn",
    type: "select",
    options: [
      {
        label: "プロテクションフィルムまたはラッピング",
        value: "protection_film_or_wrapping",
      },
      {
        label: "コーティング",
        value: "coating",
      },
      {
        label: "カスタマイズ・内外装（シート含む）",
        value: "customize_interior_exterior_including_seat",
      },
      {
        label: "ホイール",
        value: "wheel",
      },
      {
        label: "タイヤ",
        value: "tire",
      },
      {
        label: "チューニング",
        value: "tuning",
      },
      {
        label: "その他",
        value: "other",
      },
    ],
  },
];
