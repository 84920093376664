import { useContext } from "react";
import { StoreContext } from "../../store/store";
import { useGetSurvey } from "../../client-api/survey";
import React from "react";
import { withRouter } from "react-router-dom";

const HIDE_BADGE_REGEX = [/^\/settings\/[0-9a-fA-F]+\/car-edit$/];

const SurveyBadge = (props) => {
  const { state } = useContext(StoreContext);
  const { user } = state;
  const { data: surveyData } = useGetSurvey(user?._id);
  const hideBadge = HIDE_BADGE_REGEX.some((regExp) =>
    props.history.location.pathname.match(regExp)
  );
  const canShowBadge = !surveyData && !hideBadge;
  if (!canShowBadge) {
    return null;
  }

  return (
    <div
      style={{
        color: "red",
        fontSize: "15px",
        fontWeight: "bold",
        cursor: "pointer",
        padding: "20px 36px",
        background: "yellow",
      }}
      onClick={() => {
        props.history.push(`/settings/${user?._id}/car-edit`);
      }}
    >
      ※設定からあなたの愛車に対する情報を入力してください！それによりきっと良い出会いが増えるでしょう。設定画面はこちら
    </div>
  );
};

export default withRouter(SurveyBadge);
