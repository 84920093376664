import React, { useEffect, useState, useContext } from "react";
import "../Profile/style.scss";
import "./style.scss";
import { ICON_UPLOAD, ICON_ARROWBACK } from "../../Icons";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../store/store";
import Loader from "../Loader";
import { API_URL } from "../../config";
import axiosLib from "../../axiosLib";
import { useLocalization } from "../../hooks/useLocalization";
import { supportedImageFiletypes } from "../../utils/common";
import Avatar from 'react-avatar';

const SCREEN_TEXT = [
  "アカウント情報の編集",
  "保存",
  "あなたのアイコン",
  "ユーザー名",
  "メールアドレス",
  "自己紹介",
];

const ProfileEdit = (props) => {
  const { state, actions } = useContext(StoreContext);
  const [editName, setName] = useState("");
  const [editBio, setBio] = useState("");
  const [avatar, setAvatar] = useState("");
  const { user } = state;
  const userParam = props.match.params.userid;

  const { translatedTexts: texts } = useLocalization(SCREEN_TEXT);

  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (!(currentToken && currentToken.length > 0)) {
      window.location = "/login";
    }
    window.scrollTo(0, 0);
    actions.getUser(props.match.params.userid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.userid]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  useEffect(() => {
    if (user && !avatar) {
      setAvatar(user.profileImg);
    }
  }, [avatar, user])


  const editProfile = () => {
    let values = {
      name: editName,
      description: editBio,
      profileImg: avatar,
      _id: userParam,
    };
    actions.updateUser(values);
    props.history.push(`/settings/${props.match.params.userid}`);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const uploadImage = (files) => {
    let bodyFormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      bodyFormData.append("images", files[i]);
    }

    axiosLib
      .post(`${API_URL}/carvibes/upload`, bodyFormData)
      .then((res) => {
        let imgUrl = res.data.imageUrls.length > 0 ? res.data.imageUrls[0] : "";

        setAvatar(imgUrl);
      })
      .catch((err) => {
        console.log(err);
        alert("error uploading image");
      });
  };

  const changeAvatar = () => {
    let file = document.getElementById("avatar").files[0];
    uploadImage([file], "avatar");
  };

  return (
    <div className="profile-edit-root">
      {user ? (
        <div>
          <div className="modal-edit">
            <div onClick={(e) => handleModalClick(e)} className="modal-content">
              <div className="modal-header">
                <div className="modal-closeIcon">
                  <div
                    onClick={() => props.history.goBack()}
                    className="modal-closeIcon-wrap"
                  >
                    <ICON_ARROWBACK />
                  </div>
                </div>
                <p
                  className="modal-title"
                  style={{
                    fontSize: "0.8em",
                  }}
                >
                  {texts[0]}
                </p>

                <div className="save-modal-wrapper">
                  <div onClick={editProfile} className="save-modal-btn">
                    {texts[1]}
                  </div>
                </div>
              </div>

              <div className="modal-body">
                <div className="sp-header">
                  <div
                    className="modal-closeIcon-wrap"
                    onClick={() => props.history.goBack()}
                  >
                    <ICON_ARROWBACK color="#ffffff" />
                  </div>
                  <p className="modal-title">{texts[0]}</p>
                </div>

                <div className="modal-profile-pic-wrapper">
                  <p>{texts[2]}</p>
                  <div className="edit-profile-modal-pic">
                    <div
                      className="edit-back-pic"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Avatar
                        name={user.name}
                        src={avatar}
                        size={120}
                        round={true}
                      ></Avatar>
                      <div>
                        <ICON_UPLOAD />
                        <input
                          onChange={() => changeAvatar()}
                          title=" "
                          id="avatar"
                          accept={supportedImageFiletypes.join(", ")}
                          style={{ opacity: "0" }}
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <form className="edit-form">
                  <div className="edit-input-wrap">
                    <div className="edit-input-content">
                      <label>
                        {texts[3]} <sup>*</sup>
                      </label>
                      <input
                        defaultValue={user.name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        name="name"
                        className="edit-input"
                      />
                    </div>
                  </div>
                  <div className="edit-input-wrap">
                    <div className="edit-input-content">
                      <label>
                        {texts[4]} <sup>*</sup>
                      </label>
                      <input
                        defaultValue={user.email}
                        disabled
                        type="text"
                        name="name"
                        className="edit-input"
                      />
                    </div>
                  </div>
                  <div className="edit-input-wrap">
                    <div className="edit-input-content">
                      <label>
                        {texts[5]} <sup>*</sup>
                      </label>
                      <textarea
                        defaultValue={user.description}
                        onChange={(e) => setBio(e.target.value)}
                        type="text"
                        name="bio"
                        className="edit-input"
                      />
                    </div>
                  </div>
                  <div
                    className="sp-save-modal-wrapper"
                    style={{
                      margin: "20px auto 0 auto"
                    }}
                  >
                    <div
                      onClick={editProfile}
                      className="save-modal-btn"
                      style={{ color: "white" }}
                    >
                      {"保存"}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-wrapper">
          <Loader />{" "}
        </div>
      )}
    </div>
  );
};

export default withRouter(ProfileEdit);
