import { useState } from "react";
import axios from "../axiosLib";
import { useEffect } from "react";
import { API_URL } from "../config";

export const useGetUserCnt = () => {
  const [count, setCount] = useState(0);
  const getUserCnt = () => {
    axios
      .get(`${API_URL}/auth/user-cnt`)
      .then((res) => {
        const userCount = res.data.count;
        setCount(userCount ?? 0);
      })
      .catch((err) => {
        console.error("error get user cnt", err);
      });
  };

  useEffect(() => {
    getUserCnt();
  }, []);

  return {
    count,
  };
};
