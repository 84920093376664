import React, { useEffect, useRef } from "react";
import { useGetChatConversation, useUpdateChatMessage } from "../../client-api";
import ChatUser from "./ChatUser";
import ChatInput from "./ChatInput";
import ChatOutgoingMessage from "./ChatOutgoingMessage";
import ChatIncomingMessage from "./ChatIncomingMessage";
import { useChatScroll } from "./useChatScroll";
import { useSocketClient } from "../../hooks/useSocketClient";
const ChatMessages = ({ roomId, account, onBack }) => {
  const messagesEndRef = useRef(null);
  const {
    data: messageData,
    isLoading: isMessageLoading,
    refetch: onRefreshMessages,
  } = useGetChatConversation(roomId);

  const { mutateAsync: onMakeAsRead } = useUpdateChatMessage(roomId);

  const handleChatMessage = (msg) => {
    if (msg.chatRoomId === roomId && msg.postedByUser !== account._id) {
      onRefreshMessages();
    }
  };

  const { emitMessage } = useSocketClient({
    subscribeId: roomId,
    handleMsg: handleChatMessage,
  });

  useEffect(() => {
    onMakeAsRead(roomId);
  }, [roomId]);

  const conversations = messageData?.conversation ?? [];
  const roomData = messageData?.room ?? null;
  const activeUser = roomData
    ? roomData.userIds.find((user) => user._id !== account._id)
    : {};

  const chatListRef = useChatScroll(conversations);

  const isLoading = isMessageLoading || !roomData;

  const scrollToBottom = () => {
    if (messagesEndRef.current !== null) {
      messagesEndRef.current.scrollIntoView();
    }
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Chat-message">
      <ChatUser user={activeUser} onBack={onBack} />
      <div ref={chatListRef} className="Chat-message-container">
        <div className="Chat-messages-container">
          {conversations.map((message) => {
            const isOutgoing = message.postedByUser === account._id;
            const user = roomData.userIds.find(
              (user) => user._id === message.postedByUser
            );
            if (isOutgoing) {
              return (
                <ChatOutgoingMessage key={message._id} message={message} />
              );
            } else {
              return (
                <ChatIncomingMessage
                  key={message._id}
                  message={message}
                  user={user}
                />
              );
            }
          })}
        </div>
      </div>
      <ChatInput
        account={account}
        roomId={roomId}
        scrollToBottom={scrollToBottom}
      />
      <div ref={messagesEndRef}></div>
    </div>
  );
};
export default ChatMessages;
