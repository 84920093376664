import React, { useEffect, useContext, useState } from "react";
import { StoreContext } from "../../../store/store";
import "./style.scss";
import Loading from "../../loading";
import UserAvatar from "../../UserAvatar";
import TableHeaderItem from '../../Admin/components/TableHeaderItem';
import TableItem from '../../Admin/components/TableItem';
import { getDatetimeFormat } from "../../../utils/common";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const NewUserHistory = () => {
  const { state, actions } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentToken = localStorage.getItem("carvibestoken");
    if (currentToken && currentToken.length > 0) {
      actions.getUsers();
    } else {
      window.location = "/admin-login";
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);


  const tableHeader = () => {
    return (
      <div className="admin-carvibes-cell-title" style={{
        flexDirection: "row",
        height: "40px",
        backgroundColor: "#F7FAFC",

      }}>
        <TableHeaderItem title="登録日時" width="200px" />
        <TableHeaderItem title="ユーザーID" width="400px" />
        <TableHeaderItem title="ユーザー名" width="400px" />
      </div>
    );
  }


  if (loading) return Loading();

  return (
    <div className="admin-wrapper" style={{
      backgroundColor: "#fff"
    }}>
      <div className="admin-header-wrapper">
        <h2 className="admin-title">新規ユーザー履歴</h2>
      </div>
      <div className="admin-content-wrapper">
        {tableHeader()}
        <div>
          {state.users.length > 0 ? (
            state.users.map((t) => {
              return (
                <Link
                  className="Nav-link"
                  to={{ pathname: `/admin/user-list/${t._id}`, state: { user: t } }}
                >
                  <div className="admin-carvibes-cell" key={t._id}>
                    <TableItem title={dayjs(t.createdAt).format(getDatetimeFormat("JP"))} width="200px" />
                    <TableItem title={t._id} width="400px" />
                    <div className="card-userPic-wrapper" style={{
                      width: "400px",
                      flexBasis: "unset",
                    }}>
                      <UserAvatar
                        user={t}
                        size={32}
                      />
                      <TableItem title={t.name} fontSize="14px" />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              width: "100%",
            }}>
              <span style={{
                fontSize: "16px",
                fontWeight: "400",
              }}>登録されたユーザーがいません。</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewUserHistory;
