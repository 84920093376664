import { useMutation, useQueryClient } from "react-query";
import { getSurvey } from "./queryKey";
import axiosLib from "../../axiosLib";
import { API_URL } from "../../config";

export const createSurveyRequest = async (data) => {
  const response = await axiosLib.post(`${API_URL}/survey/initiate`, data);
  if (response.data.success) {
    return response.data.survey;
  }
  return null;
};

export const useCreateSurvey = (userId) => {
  const queryClient = useQueryClient();
  return useMutation(createSurveyRequest, {
    onSettled: () => {
      queryClient.invalidateQueries(getSurvey(userId));
    },
  });
};
