import React from 'react';


const TableHeaderItem = ({ title, width, ascending, setAscending, icon }) => {
    return (
        <div
            onClick={() => {
                if (icon) {
                    setAscending(!ascending);
                }
            }}
            style={{
                width: width,
                flexBasis: "unset",
                display: "flex",
                alignItems: "center",
                cursor: icon ? "pointer" : "default",
            }}
        >
            <div
                style={{
                    marginRight: icon ? "8px" : "8px",
                }}
            >
                <span className="admin-header-title">{title} </span>
            </div>
            {icon && icon}
        </div>
    );
};

export default TableHeaderItem;