import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { StoreContext } from "../../store/store";
import { Link } from "react-router-dom";
import { ICON_CLOSE } from "../../Icons";
import UserAvatar from "../UserAvatar";

const ModalFollowings = ({ show, userId, onClose }) => {
  const { state, actions } = useContext(StoreContext);
  const [tab, setTab] = useState("Following");
  const { account, followers, following, session } = state;

  useEffect(() => {
    actions.getFollowers(userId);
  }, [userId]);

  const followUser = (e, id) => {
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    e.stopPropagation();
    actions.followUser({ id, userId: userId });
  };

  const followerUser = (e, id) => {
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    e.stopPropagation();
    actions.followerUser({ id, userId: userId });
  };
  return (
    <div
      onClick={onClose}
      style={{ display: show ? "block" : "none" }}
      className="modal-edit"
    >
      <div onClick={(e) => e.stopPropagation()} className="modal-content">
        <div style={{ justifyContent: "flex-end" }} className={"modal-header"}>
          <div className="modal-closeIcon">
            <div onClick={onClose} className="modal-closeIcon-wrap">
              <ICON_CLOSE />
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="explore-nav-menu">
            <div
              onClick={() => setTab("Following")}
              className={
                tab == "Following"
                  ? `explore-nav-item activeTab`
                  : `explore-nav-item`
              }
            >
              {following.length}フォロー
            </div>
            <div
              onClick={() => setTab("Followers")}
              className={
                tab == "Followers"
                  ? `explore-nav-item activeTab`
                  : `explore-nav-item`
              }
            >
              {followers.length}フォロワー
            </div>
          </div>
          <div className="modal-scroll">
            {tab === "Following"
              ? following.map((f) => {
                  return (
                    <div key={f._id} className="search-result-wapper">
                      <Link
                        to={`/profile/${f._id}`}
                        className="search-userPic-wrapper"
                        onClick={onClose}
                      >
                        <UserAvatar user={f} />
                      </Link>
                      <div className="follow-user-details">
                        <div className="follow-user-warp">
                          <div className="search-user-info">
                            <div className="search-user-name">{f.name}</div>
                            {f.description && (
                              <div className="search-user-bio">
                                {f.description.substring(0, 160)}
                              </div>
                            )}
                          </div>
                          {f._id !== account._id && (
                            <div
                              onClick={(e) => followUser(e, f._id)}
                              className={
                                account && account.following.includes(f._id)
                                  ? "unfollow-btn"
                                  : "follow-btn"
                              }
                            >
                              {account && account.following.includes(f._id)
                                ? "フォロー解除"
                                : "フォロー"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : followers.map((f) => {
                  return (
                    <div key={f._id} className="search-result-wapper">
                      <Link
                        to={`/profile/${f._id}`}
                        className="search-userPic-wrapper"
                        onClick={onClose}
                      >
                        <UserAvatar user={f} />
                      </Link>
                      <div className="follow-user-details">
                        <div className="follow-user-warp">
                          <div className="search-user-info">
                            <div className="search-user-name">{f.name}</div>
                            {f.description && (
                              <div className="search-user-bio">
                                {f.description.substring(0, 160)}
                              </div>
                            )}
                          </div>
                          {f._id !== account._id && (
                            <div
                              onClick={(e) => followerUser(e, f._id)}
                              className={
                                account && account.followers.includes(f._id)
                                  ? "unfollow-btn"
                                  : "follow-btn"
                              }
                            >
                              <span>
                                <span>
                                  {account && account.followers.includes(f._id)
                                    ? "フォロー解除"
                                    : "フォロー"}
                                </span>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalFollowings;
