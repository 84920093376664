import React, { useEffect, useContext, useState } from "react";
import { StoreContext } from "../../../store/store";
import "./style.scss";
import Loading from "../../loading";
import UserAvatar from "../../UserAvatar";
import { ICON_SEARCH } from "../../../Icons";
import { Link } from "react-router-dom";
import TableHeaderItem from '../../Admin/components/TableHeaderItem';
import TableItem from '../../Admin/components/TableItem';

const UserList = () => {
  const { state, actions } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentToken = localStorage.getItem("carvibestoken");
    if (currentToken && currentToken.length > 0) {
      actions.getUsers();
    } else {
      window.location = "/admin-login";
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const results = state.users.filter(user =>
      user._id.toLowerCase().includes(searchTerm) ||
      user.name.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm, state.users]);


  if (loading) return Loading();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const tableHeader = () => {
    return (
      <div className="admin-carvibes-cell-title" style={{
        flexDirection: "row",
        height: "40px",
        backgroundColor: "#F7FAFC",

      }}>
        <TableHeaderItem title="ユーザーID" width="500px" />
        <TableHeaderItem title="ユーザー名" width="500px" />
      </div>
    );
  }

  return (
    <div className="admin-wrapper" style={{
      backgroundColor: "#fff"
    }}>
      <div className="admin-header-wrapper">
        <h2 className="admin-title">ユーザー一覧</h2>
      </div>
      <div className="explore-search-wrapper">
        <div className="explore-search-icon">
          <ICON_SEARCH />
        </div>
        <div className="explore-search-input">
          <input placeholder="Search" type="text" name="search" value={searchTerm} onChange={handleSearch} />
        </div>
      </div>
      <div className="admin-content-wrapper">
        {tableHeader()}
        <div>
          {state.users.length > 0 ? (
            searchResults.map((t) => {
              return (
                <Link
                  className="Nav-link"
                  to={{ pathname: `/admin/user-list/${t._id}`, state: { user: t } }}
                >
                  <div className="admin-carvibes-cell" key={t._id}>
                    <TableItem title={t._id} width="500px" />
                    <div className="card-userPic-wrapper" style={{
                      width: "500px",
                      flexBasis: "unset",
                    }}>
                      <UserAvatar
                        user={t}
                        size={32}
                      />
                      <TableItem title={t.name} fontSize="14px" />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              width: "100%",
            }}>
              <span style={{
                fontSize: "16px",
                fontWeight: "400",
              }}>登録されたユーザーがいません。</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserList;
